import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { handleApiError } from '../functions/api-errors.function';
import { DealerModel } from '../models/dealer.model';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
})
export class DealerService {
    private url = '';
    constructor(private http: HttpClient,
        private dataService: DataService) { }


    getDealerAdmin(userxds: string, usuariodealer:string) {
        this.url = `/UserManager/GetDealerAdmin?userxds=${userxds}&usuariodealer=${usuariodealer}`;
        return this.dataService.get(this.url);
    }

    postUserDealer(conc) {
        this.url = `/UserManager/postUserDealer`;
        return this.dataService.post(this.url, conc);
    }

    getUserDealer(userxds: string) {
        this.url = `/UserManager/GetUserDealer?userxds=${userxds}`;
        return this.dataService.get(this.url);
    }

    getByUserLogin(userLogin: string): Observable<DealerModel[]> {
        let queryParams: HttpParams = new HttpParams();
        queryParams = queryParams.append('userLogin', userLogin);

        return this.http
            .get<DealerModel[]>(
                `${environment.baseUrl.api}/Dealer/getAll`,
                {
                    params: queryParams,
                }
            )
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    return handleApiError(errorResponse);
                })
            );
    }

    getDealerName(id: string): Observable<DealerModel> {
        if (!id) {
            return of(null);
        }

        let queryParams: HttpParams = new HttpParams();
        queryParams = queryParams.append('dealerId', id);

        return this.http
            .get<DealerModel>(
                `${environment.baseUrl.api}/Dealer/getDealerById`,
                {
                    params: queryParams,
                }
            )
            .pipe(map((result) => result));
    }

    getDealler(user: String) {
        this.url = `/Concessionario/Concessionario?userLogin=${user}`
        return this.dataService.get(this.url, null);
    }
}

//api/RepresentanteServico
