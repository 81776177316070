<div class="modal-header">
    <h5 class="modal-title">Campanha</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row justify-content-center">
        <div class="col-6">
            <p class="fs-2 text-danger">Concessionária:&nbsp;&nbsp;{{casa2}}</p>
        </div>

        <div class="fs-6">
            <p class="fs-2 text-danger">Chassis:&nbsp;&nbsp;{{chassi2}}</p>
        </div>
    </div>

    <div class="row ml-2 mr-2">
        <div class="table-responsive">
            <table class="table table-strict table-striped">
                <thead>
                    <tr>
                        <th class="text-left control-label">Campanha</th>
                        <th class="text-left control-label">Referência</th>
                        <th class="text-center control-label">Data Início</th>
                        <th class="text-center control-label">Data término</th>
                        <th class="text-center control-label" >Serviço executado</th>
                        <th class="text-center control-label" >Data Execução</th>
                        <th class="text-center control-label" >Conc</th>
                        <th class="text-center control-label" >Nº Reclamação</th>
                        <th class="text-center control-label" >Tipo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cm of campanhaModel2; let i = index" >

                        <td class="text-left control-label">{{cm.cod_campanha}}</td>
                        <td class="text-left control-label">{{cm.referencia_campanha}}</td>
                        <td class="text-center control-label">{{cm.dt_inicio_campanha | date: 'dd-MM-yyyy'}}</td>
                        <td class="text-center control-label">{{cm.dt_termino_campanha | date: 'dd-MM-yyyy'}}</td>
                        <td class="text-center control-label" >{{cm.ctrl_execucao}}</td>
                        <td class="text-center control-label" >{{cm.dt_execucao | date: 'dd-MM-yyyy'}}</td>
                        <td class="text-center control-label" >{{cm.matriz_reparo}}</td>
                        <td class="text-center control-label" >{{cm.num_reparo}} / {{cm.ano_reparo}} </td>
                        <td class="text-center control-label" >{{cm.tipo_campanha}}</td>

                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary bg-danger text-white" data-dismiss="modal" (click)="onClose()"
        >
        Sair</button>
</div>