import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { Observable } from "rxjs";
import { ClienteEspecial } from "../models/clienteespecial.model";
import { ResultApi } from "../interfaces/envelopes.interface";


@Injectable({
    providedIn: 'root',
  })
  
  export class ClienteEspecialService {
    private url: string = '';
  
    constructor(private dataService: DataService) { }

    getClienteEspecial(usuarioLogado: string) {
        this.url = `/Cliente/ListClienteEspecial?usuarioLogado=${usuarioLogado}`
        return this.dataService.get(this.url, null);
      }

  }