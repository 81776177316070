import { MenuModel } from './../../models/menu.model';
import { Component, HostListener, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import * as _ from 'underscore';
import { MenuService } from './../../services/menu.service';
import { JwtDecodedModel } from '../../models/jwt-decoded.model';
import { Subscription } from 'rxjs';
import { DocumentsService } from '../../services/documents.service';
import { ToastService } from '../../services/toast.service';
import { ListDocumentsModel } from '../../models/list-documents.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserAccessService } from '../../services/user-manager.service';
import { UserFGModel } from '../../models/userfg.model';
import { UserService } from '../../services/user.service';

@Component({
    selector: '[navigation]',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
})
export class MenuComponent {
    @Input() listMenus: MenuModel[] = [];
    @Input() listSubMenus: MenuModel[] = [];
    active: MenuModel = {};

    logovisibile: boolean = false;
    user: JwtDecodedModel;
    userAuthentication = false;

    notificationUser: number;
    notificationNew: string;
    arquivosNovos = [];
    listDocumentsModel: ListDocumentsModel[] = []
    formMercado : FormGroup;
    qtdeUltimosDias: number = 0;
    somenteVigentes: boolean = true;
    valorSelecionado: any;
    private subscription: Subscription[] = [];

    routerLinkActive = '';
    valorSelecionadoStorage: any
    userFG: UserFGModel;

    constructor(private authService: AuthService,
                private router: Router,
                private menuService: MenuService,
                private userManager: UserAccessService,
                private documentsService: DocumentsService,
                private formBuilder: FormBuilder,
                private notifyService : ToastService,
                private userService: UserService) {
        if (this.listSubMenus.length > 0) {
            _.sortBy(this.listSubMenus, 'id_order_by');
        }

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) =>
                    event.url === '/'
                        ? ['/home']
                        : event.url.match(/^\/(\w+)/gm)
                )
            )
            .subscribe(
                (segmentPath) =>
                    (this.active =
                        this.listMenus.find(
                            (item) => item.routerLink === segmentPath[0]
                        ) || {})
            );

            this.subscription.push(
                this.authService
                    .checkAuthentication()
                    .pipe(
                        map((value) => (this.userAuthentication = value)),
                        filter((value) => !!value),
                        map(() => this.authService.getUserData())
                    )
                    .subscribe((user) => (this.user = user))
            );
            this.formMercado = this.formBuilder.group({
                mercado: [null],
              });
	}

    ngOnInit(){
        
        this.valorSelecionadoStorage = localStorage.getItem("valor");
        if ((this.valorSelecionadoStorage == 'null') || (this.valorSelecionadoStorage == 'undefined'))
        {
            localStorage.setItem('valor', 'LB');
            this.valorSelecionadoStorage = 'LB';
        }
           
        let user = this.userService.getUserFG().subscribe(
            dealer => {
              this.userFG = dealer;
              
              if(this.valorSelecionadoStorage != undefined && (this.userFG.tipo == 1 || this.userFG.tipo == 4)) {

                let user = this.authService.getUserData();
                 let usuarioLogado = user.wam_user.xds.toUpperCase();
                 this.userManager.patchAlteraMu(usuarioLogado, this.valorSelecionadoStorage).subscribe(
                     result => {
                         if (result.tipomessage === 'success')
                         {
    
                         }
                     }
                 )
    
             } else {
              
    
            //    let user = this.authService.getUserData();
            //      let usuarioLogado = user.wam_user.xds.toUpperCase();
            //      this.userManager.patchAlteraMu(usuarioLogado, this.valorSelecionadoStorage).subscribe(
            //          result => {
            //              if (result.tipomessage === 'success')
            //              {
    
            //              }
            //          }
            //      )
             }
            });

        //usuarios
       
       
       

       


        this.notifyUser()
        this.listDocuments(this.qtdeUltimosDias, this.somenteVigentes);

        this.documentsService.EmmitDownload
        .subscribe((value: string) => {
            this.notifyUser()
        });

        this.documentsService.EmmitUpload
        .subscribe((nome: string) => {
           let nomes = nome
           this.notifyUser();
           this.showToasterSuccess(nomes);
        });

        this.documentsService.EmmitDelete.subscribe((value: string) => {
            this.notifyUser();
        });

        this.documentsService.EmmitNameChange.subscribe((value: string) => {
            this.notifyUser();
        });

        this.documentsService.EmmitUploadAll
        .subscribe((value: string) => {
            this.listDocuments(this.qtdeUltimosDias, this.somenteVigentes);
        });

    }

    showToasterSuccess(nome){
        const hour = new Date().toLocaleTimeString();
        const timeElapsed = Date.now();
        let today = new Date(timeElapsed);
        let dias = today.toLocaleDateString() ;

        var data = new Date();
        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        const dataAtual = dia + '/' + mes + '/' + ano;

        this.notifyService.showSuccess("<b>Você enviou o Arquivo: </b>"+nome+"<b> no dia: </b>"+dataAtual+"<b> às: </b>"+hour, "Arquivo Enviado");
    }

    listDocuments(qtdeUltimosDias: number,somenteVigentes: boolean){

        this.documentsService.getDocumentList(qtdeUltimosDias, somenteVigentes)
        .subscribe(
          ret => {
            this.listDocumentsModel = ret.content;

            if(this.listDocumentsModel.length > 0) {

                const timeElapsed = Date.now();
                let today = new Date(timeElapsed);
                let d = today.toLocaleDateString();
                let todayApi = new Date(this.listDocumentsModel[0].data_inclusao);
                let d2 = todayApi.toLocaleDateString();

                this.arquivosNovos = []

                for(var i = 0; i < this.listDocumentsModel.length; i++) {

                    let todayModel = new Date(this.listDocumentsModel[i].data_inclusao);
                    let d2Model = todayModel.toLocaleDateString();

                    if(this.listDocumentsModel[i].notificar == "S" && d == d2Model){
                        this.arquivosNovos.push(this.listDocumentsModel[i]);
                    }
                  }

                  if(this.arquivosNovos.length >= 1) {

                    var data = new Date();
                    var dia = String(data.getDate()).padStart(2, '0');
                    var mes = String(data.getMonth() + 1).padStart(2, '0');
                    var ano = data.getFullYear();
                    const dataAtual = dia + '/' + mes + '/' + ano;


                        if(this.arquivosNovos.length == 1) {
                            this.notifyService.showInfo("<b>Documento enviado no dia: </b>"+dataAtual, "Novo Documento");

                        } else {
                            this.notifyService.showInfo("<b>Existem Documentos novos, enviados no dia: </b>"+dataAtual, "Novos Documentos");
                        }
                  }
            }

          }
        )
      }

    logout(): void {
        this.authService.userLogout();
        window.location.reload();
    }

    @HostListener('window:scroll')

    scrollHandler() {
        const scrollPage = (<HTMLInputElement>document.getElementById("menubody")).getAttribute('stuck');
        if(scrollPage !== null){
            this.logovisibile = true
        }else {
            this.logovisibile = false

        }
    }

    notifyUser() {
        this.documentsService.getNotificationAmount()
        .subscribe(
            ret => {
                this.notificationUser = ret.content.total_nao_lidos;

                if(this.notificationUser == 0) {
                    this.notificationUser = null
                }

                this.notificationNew = ret.content.novo_arquivo

            }
        )
    }

    alteraMu(event){
        
        let user = this.authService.getUserData();

        let usuarioLogado = user.wam_user.xds.toUpperCase();
        let mercado = this.formMercado.get('mercado').value;

        this.valorSelecionado = this.formMercado.get('mercado').value

        localStorage.setItem("valor", this.valorSelecionado);

         this.userManager.patchAlteraMu(usuarioLogado, mercado).subscribe(
             result => {
                 if (result.tipomessage === 'success')
                 {

                     this.router.navigate(['/home']);
                 }
             }
         )
    }
}
