import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { from, iif, of, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { MenuModel } from '../../models/menu.model';
import { AuthService } from '../../services/auth.service';
import { MenuService } from '../../services/menu.service';

@Component({
    selector: 'app-body',
    templateUrl: './body.component.html',
    styleUrls: ['./body.component.css'],
})
export class BodyComponent implements OnInit, OnDestroy {
	[x: string]: any;
    userAuthentication = false;

    listMenus: MenuModel[] = [];
    listSubMenus: MenuModel[] = [];

    private subscription: Subscription[] = [];

    constructor(
        private authService: AuthService,
        private menuService: MenuService,
        private spinner: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.subscription.push(
            this.authService
                .checkAuthentication()
                .pipe(
                    tap((value) => (this.userAuthentication = value)),
                    filter((value) => !!value),
                    map(() => this.authService.getUserData()),
                    switchMap((xdsUser) =>
                        iif(
                            () => this.listMenus.length <= 0,
                            this.getMenuByUserLogin(xdsUser.wam_user.xds),
                            of(false)
                        )
                    )
                )
                .subscribe()
        );
    }

    getMenuByUserLogin(userName: string) {
        return from(this.spinner.show()).pipe(
            take(1),
            switchMap(() => this.menuService.getMenu(userName, '1')),
            map((menus) => (this.listMenus = menus.content)),
            tap(() =>
                this.listMenus.map(
                    (menu) => (this.listSubMenus = menu.subMenus)
                )
            ),
            finalize(() => this.spinner.hide())
        );
    }

    ngOnDestroy(): void {
        this.subscription.forEach((value) => value.unsubscribe());
    }
}
