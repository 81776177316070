import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PermissionsFilialModel } from 'src/app/shared/models/PermissionsFilialModel.model';
import { UserAccessDelModel } from 'src/app/shared/models/user-access-del.model';
import { UserAccessSetModel } from 'src/app/shared/models/user-accessset.model';
import { UserModel } from 'src/app/shared/models/user-list.model';
import { UserFGModel } from 'src/app/shared/models/userfg.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { UserAccessService } from 'src/app/shared/services/user-manager.service';
import { UserService } from 'src/app/shared/services/user.service';
import Swal from 'sweetalert2';

export class deleteUser {
	userLogged: string;
    userXds:string;
}
@Component({
	selector: 'app-delete-permission-xds-modal',
	templateUrl: './delete-permission-xds-modal.component.html',
	styleUrls: ['./delete-permission-xds-modal.component.scss'],
})
export class DeletePermissionXdsModalComponent implements OnInit {
	@Input() user_login: string;
	@Input() e_mail: string;
	@Input() nome_completo: string;
	@Input() usuario: string;
	@Input() tipo: number;
	@Input() mu: string;

	voltar: boolean = true;
	userFG: UserFGModel; //Federation Gateway
	listOfUsers: UserModel[] = [];
	form: FormGroup;
	ok: boolean;
	constructor(
		private globalService: GlobalService,
		private userAccessService: UserAccessService,
		private userService: UserService,
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private modalService: BsModalService,
	) {
		this.form = this.formBuilder.group({
			user_login: [null, Validators.required],
			e_mail: [
				null,
				[
					Validators.required,
					Validators.email,
					Validators.minLength(6),
				],
			],
			nome_completo: [null, Validators.required],
			usuario: [null, Validators.required],
			tipo: [null, Validators.required],
			mu: [null, Validators.required],
		});
	}

	ngOnInit() {
		let user = this.userService.getUserFG().subscribe((dealer) => {
			this.userFG = dealer;
		});
	}

	handleDeleteAccessUser(user: string): void {
		const userMenu: UserAccessSetModel = {
			menuId: null,
			subMenuId: null,
			xdsUser: user,
		};

		Swal.fire({
			title: `Você realmente deseja remover todos os acessos do usuário (${user})?`,
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: '#033a6a',
			cancelButtonColor: '#CC1040',
			allowOutsideClick: false,
			confirmButtonText: 'Confirmar',
		}).then((result) => {
			if (result.isConfirmed) {
				this.deleteUserAccessBySystemId(userMenu).subscribe(
					(result) => {
						// this.globalService.alert('Excluido','Acessos excluidos com sucesso!','success');
						//this.close();
					}
				);
			} else
				Swal.fire({
					title: `Erro ao excluir acessos do usuário (${user})`,
					icon: 'error',
				});
		});
	}
	private deleteUserAccessBySystemId(userMenu: UserAccessDelModel): any {
		return this.userAccessService
			.deleteUserAccessBySystemId(userMenu)
			.subscribe((result) => {
				if (result.s_msg === 'OK') {
					//this.close();
					Swal.fire({
						title: 'Acessos excluídos com sucesso',
						icon: 'success',
					});
				} else
					Swal.fire({
						title: result.s_msg,
						icon: 'error',
					});
			});
	}

	onHandleDeleteUser() {
		let deleteUserBaseOk = new deleteUser();
		let userS = this.authService.getUserData();

		deleteUserBaseOk.userLogged = userS.wam_user.xds.toUpperCase();
		deleteUserBaseOk.userXds = this.user_login;

		Swal.fire({
			title: `Você realmente deseja remover o usuário da base de dados (${this.user_login})?`,
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: '#033a6a',
			cancelButtonColor: '#CC1040',
			allowOutsideClick: false,
			confirmButtonText: 'Confirmar',
		}).then((result) => {
			if (result.isConfirmed) {
				this.userAccessService
					.delUserInformation(deleteUserBaseOk)
					.subscribe((result) => {
						if (result.tipomessage === 'success') {
							this.globalService.alert(
								'Acessos excluidos com sucesso',
								'',
								'success'
							);
							this.closeModal();
							// confirm => {
							// 	setTimeout(() => {
							// 	window.location.reload();
							// 	}, 600);
							// 	this.onClose();
							// 	this.close();
							// };
						} else
							Swal.fire({
								title: result.msg,
								icon: 'error',
							});
					});
			}
		});
	}

	closeModal(modalId?: number) {
		this.modalService.hide(modalId);
	}
}
