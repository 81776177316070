<a *ngFor="let menu of listMenus" [attr.slot]="menu.type + '-items'" [ngClass]="menu.subMenus ? 'parent' : ''"
    routerLink="{{ menu.routerLink }}" [routerLinkActive]="'active'">{{ menu.description }}
    <span class="badge badge-danger ms-1" title="Documentos Novos"
        *ngIf="menu.description == 'Documentos' && notificationUser !== null">{{notificationUser}}</span>
       
</a>

<!-- usar para validar o tipo de mercado -->
<!-- *ngIf="userFG?.tipo == 1 || userFG?.tipo == 4" -->
<div slot="secondary-items" [formGroup]="formMercado" *ngIf="userFG?.tipo == 1 || userFG?.tipo == 4" >
    <div class="col-sm-10" placement="top" ngbTooltip="Tooltip on top">
        <div class="form-group">
            <select class="form-control" type="text" formControlName="mercado" (change)="alteraMu($event)"
                [(ngModel)]="valorSelecionadoStorage" data-toggle="tooltip" data-placement="top"
                title="Escolha o perfil!">
                <option class="option" value="LB">
                    BRASIL
                </option>
                <option class="option" value="CE">
                    CLIENTE ESPECIAL
                </option>
            </select>
        </div>
    </div>
</div>

<!-- <div slot="secondary-items" *ngIf="logovisibile">
    Olá, {{ user.name }} <b>({{ user.wam_user.xds }})</b>
</div> -->

<div slot="secondary-items">
    <a class="point-click" (click)="logout()">SAIR</a>
</div>

<c-navigation class="secondady" slot="sub" [attr.target]="'/' + active.routerLink"
    [ngClass]="active.subMenus ? 'active' : ''">
    <a *ngFor="let subMenu of active.subMenus" [attr.slot]="subMenu.type + '-items'"
        routerLink="{{ active.routerLink }}{{ subMenu.routerLink }}" routerLinkActive="active"
        [ngClass]="routerLinkActive ? active : ''">{{ subMenu.description }}
    </a>
</c-navigation>


<!-- preparado para as notificacoes-->