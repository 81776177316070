    <div>
        <form [formGroup]="form" (ngSubmit)="onSaveChanges()">
            <div class="modal-header">
                <h4 class="modal-title">Permissões</h4>
            </div>
            <div class="modal-body table-responsive">
                <table class="table table-condensed table-sm table-borderless">
                    <tbody>
                        <tr>
                            <td>{{ nome_completo | titlecase }}</td>
                            <td>{{ e_mail | lowercase }}</td>
                            <td>{{ user_login | uppercase }}</td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <div class="row d-flex justify-content-center">
                    <h5>Revisões</h5>
                </div>


                <div class="row">
                    <div class="col-4">
                        <div class="">
                            <input
                                type="checkbox"
                                id="atendimento"
                                name="atendimento"
                                value="atendimento"
                                formControlName="atendimento"
                            />
                            <label class="mb-0 ml-2" for="atendimento">Atendimento</label>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="">
                            <input
                                type="checkbox"
                                id="cadastro"
                                name="cadastro"
                                value="cadastro"
                                formControlName="cadastro"
                            />
                            <label class="mb-0 ml-2" for="cadastro">Cadastro</label>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="">
                            <input
                                type="checkbox"
                                id="consulta"
                                name="consulta"
                                value="consulta"
                                formControlName="consulta"
                            />
                            <label class="mb-0 ml-2" for="consulta">Consulta</label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <div class="">
                            <input
                                type="checkbox"
                                id="proprietario"
                                name="proprietario"
                                value="proprietario"
                                formControlName="proprietario"
                            />
                            <label class="mb-0 ml-2" for="proprietario">Proprietário</label>
                        </div>
                    </div>
                </div>
                <hr *ngIf = "tipo ==1 || tipo == 2">
                <div class="row d-flex justify-content-center" *ngIf = "tipo ==1 || tipo == 2">
                 <h5>Usuários</h5>
                 </div>
                <div class="row" *ngIf = "tipo ==1 || tipo == 2">
                    <div class="col-4">
                        <div class="">
                            <input
                                type="checkbox"
                                id="usuarios"
                                name="usuarios"
                                value="usuarios"
                                formControlName="usuarios"
                            />
                            <label class="mb-0 ml-2" for="usuarios">Usuários</label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row d-flex justify-content-center">
                    <h5>Documentos</h5>
                    </div>
                   <div class="row">
                       <div class="col-4">
                           <div class="">
                               <input
                                   type="checkbox"
                                   id="documentos"
                                   name="documentos"
                                   value="documentos"
                                   formControlName="documentos"
                               />
                               <label class="mb-0 ml-2" for="documentos">Boletim/Circular</label>
                           </div>
                       </div>
                       <div class="col-4" *ngIf = "cod_conc ==1">
                        <div class="">
                            <input
                                type="checkbox"
                                id="updoc"
                                name="updoc"
                                value="updoc"
                                formControlName="updoc"
                            />
                            <label class="mb-0 ml-2" for="updoc">Upload - Bol/Cir</label>
                        </div>
                    </div>
                   </div>
            </div>
            <div class="modal-footer">
                <div class="form-group">
                    <button
                        type="button"
                        class="btn btn-danger pull-right btn-action"
                        aria-label="Close"
                        (click)="onClose()"
                        >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        class="btn btn-success pull-right btn-action"
                        aria-label="Close"
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </form>
    </div>
