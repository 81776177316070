import { FilterPipe } from './pipes/filter/filter.pipe';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { BodyComponent } from './components/body/body.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { SwitchComponent } from './components/switch/switch.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { PipeModule } from './pipes/pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskDirective, NgxMaskPipe, IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { EditPermissionsUserModalComponent } from './components/modals/edit-permissions-user-modal/edit-permissions-user-modal.component';
import { CampanhaModalComponent } from './components/modals/campanha-modal/campanha-modal.component';
import { ProgramaModalComponent } from './components/modals/programa-modal/programa-modal.component';
import { AdicionalModalComponent } from './components/modals/adicional-modal/adicional-modal.component';
import { EditXdsModalComponent } from './components/modals/edit-xds-modal/edit-xds-modal.component';
import { DocumentsModalComponent } from './components/modals/documents-modal/documents-modal.component';
import { UploadDocumentModalComponent } from './components/modals/upload-document-modal/upload-document-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DocumentsModalEditComponent } from './components/modals/documents-modal-edit/documents-modal-edit.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PermissonsFilialComponent } from './components/modals/permissons-filial/permissons-filial.component';
import { DeletePermissionXdsModalComponent } from './components/modals/delete-permission-xds-modal/delete-permission-xds-modal.component';
import { RevisaoModalComponent } from './components/modals/revisao-modal/revisao-modal.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        BodyComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        SwitchComponent,
        LoginCallbackComponent,
        TableFilterComponent,
        EditPermissionsUserModalComponent,
        CampanhaModalComponent,
        ProgramaModalComponent,
        AdicionalModalComponent,
        EditXdsModalComponent,
        DocumentsModalComponent,
        UploadDocumentModalComponent,
        DocumentsModalEditComponent,
        PermissonsFilialComponent,
        DeletePermissionXdsModalComponent,
        RevisaoModalComponent
        
    ],
    imports: [
        CommonModule,
        SharedRoutingModule,
        PipeModule,
        FormsModule,
        DatePipe,
        ReactiveFormsModule,
        SweetAlert2Module.forRoot(),
        NgxSpinnerModule,
        FontAwesomeModule,
        NgxMaskDirective,
        NgxMaskPipe,
        BsDatepickerModule.forRoot(),
        NgxPaginationModule,
    ],
    exports: [
        BodyComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        SwitchComponent,
        TableFilterComponent,
        FilterPipe,
        PipeModule
    ],
    providers: [
        provideEnvironmentNgxMask(options),
    DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }