import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { jwtDecode } from "jwt-decode"


import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { UserPropsInterface } from 'src/app/shared/models/user.model';
import { UserAccessControlModel } from '../models/user-access.model';
import { UserAccessDelModel } from '../models/user-access-del.model';
import { map, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private nameToken = 'authorization';

	private userXdsCod: string = null;
	private URI: string = '/Usuario';

	constructor(private dataService: DataService, private authService: AuthService, private http: HttpClient) {
		this.userXdsCod = this.authService.getXdsUser();
	}

	getXdsUser(): string | null {

		const token = localStorage.getItem(this.nameToken);

		if (!token)
			return null;

		const userInfo = <any>jwtDecode(token);

		return userInfo.wam_user.xds
	}

	getUserFG(){
        return this.dataService.get(`${this.URI}/UsuarioFG`, null);
    }

	// retorna o usuário com interface UserProps
	public getUserByAuth(user_login: string | null = null): Observable<UserPropsInterface>{
		let queryParams: HttpParams = new HttpParams();
		queryParams = queryParams.append('user_login', (user_login) ? user_login.toUpperCase() : this.userXdsCod.toUpperCase());
		return this.dataService.get<UserPropsInterface>(`${this.URI}`, queryParams );
	}

	public getUserIsDealer(user_login: string | null = null) {
		let queryParams: HttpParams = new HttpParams();
		queryParams = queryParams.append('user_login', (user_login) ? user_login.toUpperCase() : this.userXdsCod.toUpperCase());
		return this.dataService.get<boolean>(`${this.URI}/usuarioDealer`, queryParams );
	}

	public getPermissionToAnalyzeGoodwill(user_login: string | null = null, dealer_cod: number | null = null) {
		let queryParams: HttpParams = new HttpParams();
		queryParams = queryParams.append('user_login', (user_login) ? user_login.toUpperCase() : this.userXdsCod.toUpperCase());
		if(dealer_cod){
			queryParams = queryParams.append('cod_conc', dealer_cod.toString());
		}
		return this.dataService.get<boolean>(`${this.URI}/autorizacaoAnalisarGoodwill`, queryParams );
	}
}
