import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './sections/home/home.component';
//import { UsersComponent } from './sections/users/users.component';
import { AuthGuard } from './shared/guards/auth.guard';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
    },
    {
        path: 'home',
        component: HomeComponent,
    },
	{
        path: 'report',
        loadChildren: () =>
            import('./sections/report/report.module').then(
                (m) => m.ReportModule
            ),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
    },
	{
        path: 'users',
        loadChildren: () =>
            import('./sections/users/users.module').then((m) => m.UsersModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
    },
    {
		path: 'revisoes',
		loadChildren: () => import('./sections/revisoes/revisoes.module').then(m => m.RevisoesModule),
		canLoad: [AuthGuard],
		canActivate: [AuthGuard]
	},
    {
		path: 'documents',
		loadChildren: () => import('./sections/documents/documents.module').then(m => m.DocumentsModule),
		canLoad: [AuthGuard],
		canActivate: [AuthGuard]
	}
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
