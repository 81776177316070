<button id="teste" type="button" class="btn btn-primary" data-toggle="modal" data-target="#ModalLongoExemplo" >
    Abrir modal de demonstração
  </button>

<div id="ModalLongoExemplo" class="modal fade" tabindex="-1" data-backdrop="false" role="dialog" aria-labelledby="TituloModalLongoExemplo">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="TituloModalLongoExemplo">Programa de manutenção</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Fechar" (click)="onClose()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                     <span class="text-danger sel">Ciclo</span> 
                    <select id="ciclo" class="form-control sel" [(ngModel)] = "ciclo">
                        <option value="1" >1</option>
                        <option value="2" >2</option>
                        <option value="3" >3</option>
                        <option value="4" >4</option>
                        <option value="5" >5</option>
                        <option value="6" >6</option>
                        <option value="7" >7</option>
                        <option value="8" >8</option>
                        <option value="9" >9</option>
                        <option value="10" >10</option>
                    </select>
                </div>

                <div class="col-6 mt-4">
                    <div class="form-group">
                        <button type="button" class="btm btn btn-primary pull-right" (click)="consultar()">
                            Consultar
                        </button>
                    </div>
                </div>

            </div>

            <div class="row mt-5 ml-2 mr-2">
                <div class="table-responsive">
                    <table class="table table-strict table-striped table-sm">
                        <thead>
                            <tr>
                                <th class="text-left control-label">Revisão</th>
                                <th class="text-center control-label">Intervalos</th>
                            </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let pm of programaModel; let i = index" >
                                <td class="text-left control-label">{{pm.dsc_tipo_rev}}</td>
                                <td class="text-center control-label">{{pm.km_hora_ini}} - {{pm.km_hora_fim}}</td>
                           </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onClose()">
            Fechar</button>
          <!-- <button type="button" class="btn btn-primary">Salvar mudanças</button> -->
        </div>
      </div>
    </div>
  </div>