import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArray',
})
export class FilterArrayPipe implements PipeTransform {
    transform(
        items: Array<any>,
        filter: { [key: string]: any },
        operator: string
    ): Array<any> {
        const keys = Object.keys(filter);

        if (
            items === undefined ||
            keys.length === 0 ||
            !keys.find(
                (key) =>
                    filter[key] !== '' &&
                    filter[key] !== null &&
                    filter[key] !== undefined
            )
        ) {
            return items;
        }

        return items.filter((item) => {
            return operator === 'and'
                ? !keys.find(
                      (key) =>
                          item[key] !== filter[key] &&
                          filter[key] !== '' &&
                          filter[key] !== null &&
                          filter[key] !== undefined
                  )
                : keys.find((key) => item[key] === filter[key]) !== undefined;
        });
    }
}
