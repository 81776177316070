import { Component, OnInit, Renderer2 } from '@angular/core';
import { theme as scania } from 'scania-theme';
import { environment } from 'src/environments/environment';
import { defineCustomElements, addTheme } from 'corporate-ui';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	title = 'Padrao | Scania';
	public innerWidth: any;

	constructor(private renderer: Renderer2) { }

	ngOnInit(): void {
		this.renderer.setAttribute(
			document.body,
			'data-env',
			environment.app.data_env
		);

		this.innerWidth = window.innerWidth;

		if (this.innerWidth <= 975) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'A resolução de mínima de tela suportada pelo Padrao Scania é de 1024x768. Se você estiver em um celular ou tablet, utilize um PC',
			});
		}

		// BUG DO COLAPSE [dentro de tag panel]
		$(document).on('click', '[collapse-ctrl]', function (e) {
			e.preventDefault();

			let $collapseLink = $(this);
			let expanded: string = $collapseLink.attr('aria-expanded').trim();
			let $containerColpase = $($collapseLink.attr('href'));
			let degrees = expanded? '0' : '180';
			let mt = expanded? '-10px' : '0';

			expanded = (expanded === 'false') ? 'true' : 'false';

			$collapseLink.attr('aria-expanded', expanded).css('margin-top', mt);
			$collapseLink.find('i').css({'transform' : 'rotate('+ degrees +'deg)'});
			$containerColpase.slideToggle();
		});

		// SELECT 2
		// $.fn.select2.defaults.set( "theme", "bootstrap" );
	}


}

defineCustomElements();
addTheme(scania);
