import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProdutoModel } from "../models/produto.model";
import { DataService } from "./data.service";
import { ManutencaoServicoModel } from "../interfaces/manutencao.model";
import { RevisaoDeleteModel } from "../models/revisao-delete.model";
import { RevisaoIncModel } from "../models/revisao-inc.model";
import { HttpHeaders } from "@angular/common/http";


@Injectable({
	providedIn: 'root',
})

export class RevisaoConsultaService {
    private url: string = '';
    EmmitRevision = new EventEmitter<string>();
	constructor(private dataService: DataService) { }

  getProduto(numchassi: number, nummotor: number):Observable<ProdutoModel> {
      if (numchassi != null && numchassi != 0 )
        this.url = `/Revisao/ProdutoInfo?chassi=${numchassi}`
      else 
        this.url = `/Revisao/ProdutoInfo?motor=${nummotor}`

		return this.dataService.get<ProdutoModel>(this.url, null);
	}

  getManutencao(produtorevisao: number, sistematica: number, acao: string):Observable<ManutencaoServicoModel[]> {
    this.url = `/Revisao/RevisionDetail?produtoRevisao=${produtorevisao}&sistematica=${sistematica}&acao=${acao}` 
    return this.dataService.get<ManutencaoServicoModel[]>(this.url, null);
  }

  delRevisao(revisaoDeleteModel: RevisaoDeleteModel){
    this.url = `/Revisao`;
    const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: revisaoDeleteModel
		};
    return this.dataService.deleteBody(this.url,httpOptions); 
  }

  postRevisao(revisaoInclusaoModel: RevisaoIncModel){
    this.url = `/Revisao`;
    return this.dataService.post(this.url,revisaoInclusaoModel); 
  }
  uploadRevision(nome): void {
    this.EmmitRevision.emit(nome)
  }
}
