import { TokenResponseModel } from '../models/auth-token.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    auth_cookie,
    refresh_cookie,
} from '../auth/constants';
import { jwtDecode } from "jwt-decode"
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { JwtDecodedModel } from '../models/jwt-decoded.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private nameToken = 'authorization';
    
    private checkAuthentication$: BehaviorSubject<boolean> =
        new BehaviorSubject(this.checkToken(auth_cookie));

    constructor(
        private http: HttpClient,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {}

    checkToken(cookieName: string): boolean {
        //return this.cookieService.get(cookieName) != null ? true : false
        return localStorage.getItem(cookieName) != null ? true : false;
    }

    checkAuthentication(): Observable<boolean> {
        return this.checkAuthentication$.asObservable();
    }

    checkAuthenticationCallback(): Observable<boolean> {
        return of(window.location.href.indexOf('/callback') > -1);
    }

    redirectLoginPage(): void {
        console.log('authUrl',environment.wso2.authUri)
     //   this.spinner.show();
        window.location.href = environment.wso2.authUri;
  
    }

    setToken(TokenResponseModel: TokenResponseModel): void {
        // const dateExpire = new Date();
        // dateExpire.setTime(dateExpire.getTime() + (TokenResponseModel.expires_in * 1000));

        // let cookieOptions: CookieOptions = {};

        // if (window.location.host.indexOf('localhost') !== -1) {

        //     cookieOptions = {
        //         secure: false,
        //         sameSite: 'lax',
        //         expires: dateExpire,
        //         domain: 'localhost'
        //     }

        // } else {

        //     cookieOptions = {
        //         secure: true,
        //         sameSite: 'lax',
        //         expires: dateExpire,
        //         domain: auth_cookie_domain
        //     }

        // }

        // this.cookieService.put(
        //     auth_cookie,
        //     TokenResponseModel.access_token,
        //     cookieOptions
        // );

        // this.cookieService.put(
        //     refresh_cookie,
        //     TokenResponseModel.refresh_token,
        //     cookieOptions
        // );

        localStorage.setItem(auth_cookie, TokenResponseModel.access_token);
        localStorage.setItem(refresh_cookie, TokenResponseModel.refresh_token);

        this.checkAuthentication$.next(this.checkToken(auth_cookie));
    }

    getToken(code: string): Observable<TokenResponseModel> {
        return this.http
            .post<TokenResponseModel>(environment.wso2.tokenUri, {
                code: code,
                redirect_uri: environment.wso2.redirectUri.indexOf('localhost') >= 0 ? '' : environment.wso2.redirectUri,
            })
            .pipe(take(1));
    }

    getTokenValue(): string {
        //return this.cookieService.get(auth_cookie);
        return localStorage.getItem(auth_cookie);
    }

    getResfreshTokenValue(): string {
        //return this.cookieService.get(refresh_cookie);
        return localStorage.getItem(refresh_cookie);
    }

    getUserData(): JwtDecodedModel {
        if (this.getTokenValue()) {
            const tokenDecoded = <JwtDecodedModel>(
                jwtDecode(this.getTokenValue())
            );
            return tokenDecoded;
        }

        return null;
    }

    userLogout(): void {
        localStorage.clear();

        this.checkAuthentication$.next(this.checkToken(auth_cookie));

        this.router.navigate(['home']);
    }

    getRefreshToken(refreshToken: string): Observable<TokenResponseModel> {
        return of(true).pipe(
            //tap(() => this.cookieService.remove(auth_cookie)),
            tap(() => localStorage.removeItem(auth_cookie)),
            // tap(() => this.checkAuthentication$.next(this.checkToken(auth_cookie))),
            switchMap(() =>
                this.http.post<TokenResponseModel>(environment.wso2.refrehTokenUri, {
                    refresh_token: refreshToken,
                })
            ),
            take(1)
        );
    }

    getXdsUser(): string | null {

		const token = localStorage.getItem(this.nameToken);

		if (!token)
			return null;

		const userInfo = <any>jwtDecode(token);

		return userInfo.wam_user.xds
	}
}
