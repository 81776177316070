<div [formGroup]="form">
	<div class="modal-header">
		<h4 class="modal-title">Editando usuário</h4>
	</div>
	<div class="modal-body table-responsive">
		<div class="row d-flex justify-content-center">
			<div class="col-8">
				<div class="form-group">
					<label>Nome do usuário</label>
					<input
						type="text"
						class="form-control text-left"
						formControlName="nome_completo"
						placeholder="Nome do usuário"
						maxlength="50"
					/>
				</div>
			</div>
		</div>
		<div class="row d-flex justify-content-center">
			<div class="col-8">
				<div class="form-group">
					<label>Usuário XDS</label>
					<input
						type="text"
						class="form-control text-left text-uppercase"
						formControlName="user_login"
						placeholder="xds"
						maxlength="7"
						[disabled]
					/>
				</div>
			</div>
		</div>
		<div class="row d-flex justify-content-center">
			<div class="col-8">
				<div class="form-group">
					<label>Digite o e-mail</label>
					<input
						type="text"
						class="form-control text-left text-lowercase"
						formControlName="e_mail"
						placeholder="Digite o e-mail"
						maxlength="50"
					/>
				</div>
			</div>
		</div>

		<!-- <div class="row d-flex justify-content-center">
                        <div class="col-6">
                            <div class="form-group">
                                <label>Data Expiração</label>
                                <input  class="form-control text-center"
                                        formControlName="dateStart"
                                        id="dateStart"
                                        name="dateStart"
                                        placeholder="DD/MM/AAAA"
                                        bsDatepicker
                                        [bsConfig]="{
                                          isAnimated: true,
                                          adaptivePosition: true,
                                          dateInputFormat: 'DD/MM/YYYY'
                                        }"

                                        />
                              </div>
                        </div>
                      </div> -->
		<div class="row d-flex justify-content-center">
			<div class="col-8">
				<div class="form-group">
					<label>Tipo Usuário</label>
					<select
						class="form-control"
						formControlName="tipo"
						(change)="onSelect($event.target.value)"
					>
						<option
							*ngFor="let tipo of tipos"
							value="{{ tipo.id }}"
						>
							{{ tipo.nome }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row d-flex justify-content-center">
			<div class="col-8">
				<div class="form-group">
					<label>Mercado</label>
					<select class="form-control" formControlName="mu">
						<option
							*ngFor="let mercado of mercados"
							value="{{ mercado.tipo }}"
						>
							{{ mercado.nome }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="isDuplic">
			<div class="col-12">
				<h6 class="text-danger">
					Existem usuários já cadastrados com o mesmo XDS.
				</h6>
			</div>
		</div>
		<div class="row" *ngIf="isDuplic">
			<div class="col-12">
				<table class="table table-striped table-strict">
					<thead>
						<tr>
							<th class="hands text-center">Usuário Cnet</th>
							<th class="hands text-center">Nome</th>
							<th class="hands text-center">E-mail</th>
							<th class="hands text-center">XDS</th>
							<th class="hands text-center">Ações</th>
						</tr>
					</thead>
					<tr>
						<td class="hands text-center">005-XDSSS</td>
						<td class="hands text-center">NOME DO FULANO</td>
						<td class="hands text-center">E-mail</td>
						<td class="hands text-center">XDS</td>
						<button
							class="btn btn-danger btn-inline btn-sm px-2 py-1 ml-4 mt-2"
							title="Excluir XDS"
							data-toggle="tooltip"
						>
							<i class="bi bi-trash btn-icon"></i>
						</button>
					</tr>
				</table>
			</div>
		</div>
		<!-- </form> -->
	</div>
	<div class="modal-footer">
		<div class="form-group">
			<button
				type="button"
				class="btn btn-danger pull-right btn-action"
				aria-label="Close"
				(click)="closeModal()"
			>
				Cancelar
			</button>
			<button
				type="button"
				class="btn btn-success pull-right btn-action"
				aria-label="Close"
				(click)="saveXds()"
				[disabled]="!form.valid"
			>
				Salvar
			</button>
		</div>
	</div>
</div>
