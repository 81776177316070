import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JwtDecodedModel } from '../../models/jwt-decoded.model';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnDestroy {
    user: JwtDecodedModel;
    userAuthentication = false;

    private subscription: Subscription[] = [];

    constructor(private authService: AuthService) {
        this.subscription.push(
            this.authService
                .checkAuthentication()
                .pipe(
                    map((value) => (this.userAuthentication = value)),
                    filter((value) => !!value),
                    map(() => this.authService.getUserData())
                )
                .subscribe((user) => (this.user = user))
        );
    }

    ngOnDestroy(): void {
        this.subscription.forEach((value) => value.unsubscribe());
    }

    onClickLogin(): void {
        this.authService.redirectLoginPage();
    }
}
