	<div>
		<div class="modal-header">
			<h4 class="modal-title">Exclusão de Usuário / Permissões </h4>
		</div>
		<div class="modal-body">
			<div>
				<p class="d-flex fustify-content-center">
					Qual dos acessos deseja excluir?
				</p>
			</div>
			<div class="d-flex justify-content-center">
				<button type="button" class="btn btn-secondary pull-right btn-action" aria-label="Permissões" (click)="handleDeleteAccessUser(user_login)">
					Excluir Permissões
				</button>
				<button type="button" class="btn btn-secondary pull-right btn-action" aria-label="Usuário" (click)="onHandleDeleteUser()">
					Excluir Usuário
				</button>
			</div>
		</div>

		<div class="modal-footer">
			<button type="button" class="btn btn-danger pull-right btn-action" (click)="closeModal()">
				Voltar
			</button>
		</div>
	</div>