
<div class="modal-header">
    <h5 class="modal-title">Manutenção Opcional</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
        <div class="row mt-5 ml-2 mr-2">
        <div class="table-responsive">
            <table class="table table-strict table-striped table-sm">
                <thead>
                    <tr>
                        <th class="text-left control-label">Serviços Opcionais</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let so of servicoOpcionalModel">
                        <td class="text-left control-label">{{so.srv_descr}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary bg-danger text-white" data-dismiss="modal" (click)="onClose()">
        Sair</button>
</div>

