import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentsService } from 'src/app/shared/services/documents.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { ListDocumentsModel } from 'src/app/shared/models/list-documents.model';
import { DocumentGroupsModel } from 'src/app/shared/models/document-groups.model';
import Swal from 'sweetalert2';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-documents-modal-edit',
  templateUrl: './documents-modal-edit.component.html',
  styleUrls: ['./documents-modal-edit.component.scss']
})
export class DocumentsModalEditComponent implements OnInit {

  formUpload: FormGroup;
  dateStartString: string;
  dateEndString: string;
  dateDownloadString: string;
  currentFile: any;
  private file: File;

  dateStartEdit: Date;
  dateEndEdit: Date;
  dateDownload: Date;
  tipoDoc: string;
  tipoDocSelect: any;
  habilitar = false;
  declare result: any;
  groups: DocumentGroupsModel[] = [];

  dateStart: string;
  dateEnd: string;

  idGrupo: string = ''

  @Input() id_doc: number;
  @Input() qtdeUltimosDias: number;
  @Input() somenteVigentes: boolean;
  @Input() listaDocumentos: ListDocumentsModel[] = []


  constructor(private bsModalRef: BsModalRef,
              private formBuilder: FormBuilder,
              private documentsService: DocumentsService,
              private globalService: GlobalService) 
  { 
    this.formUpload = this.formBuilder.group({
      dateStart: [this.dateStartString, Validators.required],
      dateEnd: [this.dateEndString],
      grupo: [null, Validators.required],
      assunto: [null, Validators.required],
      boletim: [null, Validators.required],
      arquivo: [null, Validators.required],
      substituicao: [null],
      download: [{ value: '', disabled: true }]
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.listDocumentsGroups(this.idGrupo);
    }, 200);
   
    this.result = this.listaDocumentos.find(({ id_arquivo }) => id_arquivo === this.id_doc);

    this.tipoDoc = this.result.tipo_arquivo

    if (this.listaDocumentos !== null) {

      if (this.result.dt_final_vigencia) {
        let value = new Date(this.result.dt_inicio_vigencia);
        let valueF = new Date(this.result.dt_final_vigencia);
        this.dateEndEdit = valueF;
        this.dateStartEdit = value;
      } else {
        let value = new Date(this.result.dt_inicio_vigencia);
        this.dateStartEdit = value;
      }
    }
    
    let valueD = new Date(this.result.ultimo_download);
    this.dateDownload = valueD;
  }

  onClose(): void {
    this.bsModalRef.hide();  }

  //conversoes de data

  onChangeDatePickerStart(value: string): void {
    this.dateStartString = new Date(value).toISOString().slice(0, 10);
  }

  onChangeDatePickerEnd(value: string): void {
    this.dateEndString = new Date(value).toISOString().slice(0, 10);
  }

  validar() {
    this.habilitar = true
  }

  //adicionar arquivo

  addfile(event: any): void {

    this.validar();

    try {

      this.currentFile = event.target.files[0].name;
      this.file = event.target.files[0];

    } catch (error) { }
  }

  //validar data inicial

  validarDtInicio(event) {

    let dtInicio: string;
    let dtInicio2: string;

    dtInicio = new Date(event).toISOString().slice(0, 10);
    let valueF = this.result.dt_inicio_vigencia;
    dtInicio2 = new Date(valueF).toISOString().slice(0, 10);

    if (dtInicio2 === dtInicio) {
      this.habilitar = false
    } else {
      this.habilitar = true
    }
  }

  //validar data final

  validarDtFim(event) {

    let dtFim: string;
    let dtFim2: string;

    dtFim = new Date(event).toISOString().slice(0, 10);
    let valueF = this.result.dt_final_vigencia;
    dtFim2 = new Date(valueF).toISOString().slice(0, 10);

    if (dtFim === dtFim2) {
      this.habilitar = false
    } else {
      this.habilitar = true
    }
  }

  idEditLine(id) {
    return id.id_arquivo === this.id_doc;
  }

  // listagem grupos
  listDocumentsGroups(idGrupo: string) {
    this.documentsService.getDocumentGroup(idGrupo)
      .subscribe(
        ret => {
          this.groups = ret.content;
    
          let found = this.groups.find(element => element.descricao === this.tipoDoc)
          this.tipoDocSelect = found

        }
      )
  }

  // editar registros
  onEdit() {
    let assunto = this.formUpload.get('assunto').value;
    this.dateStart = this.dateStartString;
    this.dateEnd = this.dateEndString;
    let boletim = this.formUpload.get('boletim').value;
    let substituicao = this.formUpload.get('substituicao').value;
    let grupo = this.formUpload.get('grupo').value;
    
    if ( this.file !== undefined) { 
      var tamanhoArquivo = parseInt(this.file.size.toString() );
      var sizeInMB = (tamanhoArquivo / (1024*1024)).toFixed(2);
    }
   
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    const dataAtual = ano + '-' + mes + '-' + dia;
    
    if(this.dateEnd === '1970-01-01') {
      this.dateEnd = null
    }

    if (this.file === undefined && grupo === null && this.dateStart === '1970-01-01' && assunto === "" && boletim === null) {
      this.globalService.alert("Atenção", "Escolha uma informação para Edição", "info");
      return;
    }

    if (this.file === undefined && grupo === null && this.dateStart === '1970-01-01') {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios", "info");
      return;
    }

    if (this.file === undefined && assunto === null) {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios: Assunto", "info");
      return;
    }
    if (this.file === undefined && assunto === "") {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios: Assunto", "info");
      return;
    }

    if (this.file === undefined && grupo === null) {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios", "info");
      return;
    }

    if (this.file === undefined && boletim === "") {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios: Boletim", "info");
      return;
    }

    if (this.file !== undefined && boletim === null) {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios: Boletim", "info");
      return;
    }

    if (this.file !== undefined && assunto === null && boletim === null ) {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios", "info");
      return;
    }

    if (this.file !== undefined && assunto === "" && boletim === "" ) {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios", "info");
      return;
    }

    if (this.file === undefined && this.dateStart === '1970-01-01') {
      this.globalService.alert("Atenção", "Preencha os campos Obrigátorios", "info");
      return;
    }

    if (this.dateStart > this.dateEnd) {
      this.globalService.alert("Atenção", "Digite uma data de divulgação menor que a data expiração", "info");
      return;
    }

    if (grupo !== null && this.dateStart !== '1970-01-01' && assunto !== null && boletim !== null) {
 
      if (this.file !== undefined) {
           if( tamanhoArquivo > 2097152) {
            this.globalService.alert("Erro", "O arquivo selecionado possui um tamanho acima do suportado - 2MB", "error"); 
            return;
           } else { 
            this.documentsService.updDocument(this.file, this.id_doc)
            .subscribe(ret => {
              const name = "alterar"
              this.documentsService.putChange(name);
            })
           }
       
       
      }

        if (this.dateEnd == null) {

          this.documentsService.updInfoDocumentDateEnd(this.id_doc, assunto, this.dateStart, grupo.idGrupo, boletim, substituicao)
            .subscribe(ret => {
              if (ret.tipomessage == 'success') {
                Swal.fire({
                  title: 'Edição realizada com sucesso',
                  icon: 'success',
                  showConfirmButton: true,
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#033a6a',
                  allowOutsideClick: false,
                });
                this.bsModalRef.hide();
                const name = "alterar"
                this.documentsService.putChange(name)
              }
            })

        } else {
          this.documentsService.updInfoDocument(this.id_doc, assunto, this.dateStart, this.dateEnd, grupo.idGrupo, boletim, substituicao)
            .subscribe(ret => {
              if (ret.tipomessage == 'success') {
                Swal.fire({
                  title: 'Edição realizada com sucesso',
                  icon: 'success',
                  showConfirmButton: true,
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#033a6a',
                  allowOutsideClick: false,
                });
                this.bsModalRef.hide();
                const name = "alterar"
                this.documentsService.putChange(name);
              } 
            })
        }

      } else {

        if (this.dateEnd == null) {

          this.documentsService.updInfoDocumentDateEnd(this.id_doc, assunto, this.dateStart, grupo.idGrupo, boletim, substituicao)
            .subscribe(ret => {
              if (ret.tipomessage == 'success') {
                Swal.fire({
                  title: 'Edição realizada com sucesso',
                  icon: 'success',
                  showConfirmButton: true,
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#033a6a',
                  allowOutsideClick: false,
                });
                this.bsModalRef.hide();
                const name = "alterar"
                this.documentsService.putChange(name)
              } 
            })

        } else {
          this.documentsService.updInfoDocument(this.id_doc, assunto, this.dateStart, this.dateEnd, grupo.idGrupo, boletim, substituicao)
            .subscribe(ret => {
              if (ret.tipomessage == 'success') {
                Swal.fire({
                  title: 'Edição realizada com sucesso',
                  icon: 'success',
                  showConfirmButton: true,
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#033a6a',
                  allowOutsideClick: false,
                });
                this.bsModalRef.hide();
                const name = "alterar"
                this.documentsService.putChange(name);
              } 
            })
        }
      }
    } 
  }
