import {
    NgModule,
    ApplicationRef,
    LOCALE_ID,
    CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PipeModule } from './shared/pipes/pipe.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { BootstrapModalModule } from 'ng2-bootstrap-modal';

import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { 
	faSearch, 
	faSortAlphaDown,
	faSortAlphaUpAlt,
} from '@fortawesome/free-solid-svg-icons';

import { AppComponent } from './app.component';
import { DatePipe, CommonModule } from '@angular/common';


import { HomeComponent } from './sections/home/home.component';
import { DataService } from './shared/services/data.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RequestInterceptor } from './shared/interceptors/request.interceptor';
import { ToastrModule } from 'ngx-toastr';
//import { BsModalService } from 'ngx-bootstrap/modal';
import { SharedModule } from './shared/shared.module';
import { BsModalService } from 'ngx-bootstrap/modal';





library.add(faSearch as IconDefinition);
library.add(faSortAlphaUpAlt as IconDefinition);
library.add(faSortAlphaDown as IconDefinition);

registerLocaleData(localePt, 'pt-BR');

export let options: Partial<IConfig> | (() => Partial<IConfig>) = {
    validation: true,
};

@NgModule({
    declarations: [AppComponent, HomeComponent],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        HttpClientModule,
        DatePipe,
        PipeModule,
        NgxSpinnerModule,
        FontAwesomeModule,
        CurrencyMaskModule,
        //BootstrapModalModule.forRoot({ container: document.body }),
        BsDatepickerModule.forRoot(),
        NgxMaskDirective,
        NgxMaskPipe,
        SharedModule,
        BsDatepickerModule.forRoot(),
        ToastrModule.forRoot({
            positionClass:'toast-bottom-right',
            progressBar: true,
            timeOut: 10000,
            progressAnimation: 'increasing',
            disableTimeOut: false,
            preventDuplicates: true,
            enableHtml: true,
            closeButton: true,
            tapToDismiss: true,

        }),
        //BsModalService,
        //TextMaskModule
    ],
    providers: [
        provideEnvironmentNgxMask(options),
        BsModalService,
        PaginatePipe,
        DataService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR',
        },
        {provide: HTTP_INTERCEPTORS,
            useClass:RequestInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(applicationRef: ApplicationRef) {
        //for ng2-bootstrap-modal in angular 5+(6, 7 etc..)
        Object.defineProperty(applicationRef, '_rootComponents', {
            get: () => applicationRef['components'],
        });
    }
}

declare module '@angular/core' {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: (Provider | EnvironmentProviders)[];
    }
}
