import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataService {

	constructor(private http: HttpClient, private router: Router) { }

	private _getUrl(end_point: string): string {
		return encodeURI(environment.baseUrl.api + end_point);
	}

	public get<T>(url: string, params: HttpParams = null) {
		return this.http.get<T>(this._getUrl(url), { params: params }).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		).pipe(
			map((value) => value)
		);
	}

	public postdel<T>(url: string, body: any) {
		return this.http.post<T>(this._getUrl(url), body).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		).pipe(
			map((value) => value.messages[0].content)
		);
	}

	public post<T>(url: string, body: any) {
		return this.http.post<T>(this._getUrl(url), body).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		);
	}

	public patch<T>(url: string, body: any) {
		return this.http.patch<T>(this._getUrl(url), body).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		);
	}

	public put<T>(url: string, body: any) {
		return this.http.put<T>(this._getUrl(url), body).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		);
	}

	public postAny(url: string, body: any, options: any) {
		return this.http.post(this._getUrl(url), body, options).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		);
	}

	public delete<T>(url: string) {
		return this.http.delete<T>(this._getUrl(url)).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		);
	}

	public deleteBody<T>(url: string,body: any) {

		return this.http.delete<T>(this._getUrl(url),body).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		);
	}

	public windowOpen(url: string, target: string = '_blank') {
		window.open(this._getUrl(url), target);
	}

	public findCep(cep: string, params: HttpParams = null){
		let urlCep = `https://viacep.com.br/ws/${cep}/json/`
		return this.http.get(urlCep, { params: params }).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		).pipe(
			map((value) => value)
		);

	}

	public deleteBodyJson<T>(url: string,body: any) {

        let bodyjson = JSON.stringify(body);
		const options = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			}),
			body: bodyjson,
		  };
		  let urlcomplete = this._getUrl(url);
        return this.http.delete<T>(urlcomplete, options).pipe(
			catchError(error => {
				this.router.navigate(['error']);
				return of(error);
			})
		);
	}
}
