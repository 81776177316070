<div class="container">
    <div class="title row">
        <div class="col-6 d-flex flex-wrap align-items-center align-content-center">   
            <br><br><br>
            <h1 class="display-1 headline"> REVISÕES</h1>
            <h1 class="text-muted display-3">Bem-vindo</h1> 
       </div>
       <div class="col-6">    
        <br><br><br>
         <img src="../../assets/home/scania-2.jpg" alt="Representação entre duas pessoas firmando um acordo"> 
       </div>
    </div>
</div>

 
