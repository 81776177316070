    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Deseja incluir a revisão com as informações abaixo?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Fechar" (click)="Close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [formGroup]="forminc">
        <div class="row">
            <div class="col-6">
                <div class="form-group ml-5">
                    <label>Chassi</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                        formControlName="chassi"/>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mr-5">
                    <label>Motor</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                        formControlName="motor"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="form-group ml-5 mr-5">
                    <label>Concessionária</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                        formControlName="concessionaria"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="form-group ml-5 mr-5">
                    <label>Revisão</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                        formControlName="revrev"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="form-group ml-5 mr-5">
                    <label>Tipo de operação</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                        formControlName="tpoperrev"/>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-4">
                <div class="form-group ml-5">
                    <label>Data</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                    formControlName="datarev"/>
                </div>
            </div>

            <div class="col-3">
                <div class="form-group">
                    <label>KM</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                    formControlName="km"/>
                </div>
            </div>

            <div class="col-2">
                <div class="form-group">
                    <label>Horas</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                    formControlName="hm"/>
                </div>
            </div>

            <div class="col-3">
                <div class="form-group mr-5">
                    <label>OS</label>
                    <input class="form-control form-control-sm -text-left" type="text"
                    formControlName="osrev"/>
                </div>
            </div>
        </div>
        

        
    </div>
    <div class="modal-footer mr-5">
        <button type="button" class="btn  btn-danger" data-dismiss="modal" (click)="Close()">Descartar</button>
        <button type="button" class="btn btn-success" (click)="salvarMudanca()">Salvar mudanças</button>
    </div>
