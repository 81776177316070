<div>
    <div class="modal-header">
        <h4 class="modal-title">
            Editar Arquivo
        </h4>
    </div>
    <div [formGroup]="formUpload">
    <div class="modal-body rolagem ml-5 mr-5">
      <div class="row d-flex justify-content-center">
        <div class="col-4" style="margin-bottom: 3px!important;">
          <div class="form-group">
            <label>Ultimo Download</label>
            <span *ngIf="this.result.ultimo_download != null">
              <input class="form-control text-center"
                   formControlName="download"
                   [(ngModel)]="dateDownload"
                   bsDatepicker
                   [bsConfig]="{
                     isAnimated: true,
                     adaptivePosition: true,
                     dateInputFormat: 'DD/MM/YYYY'
                   }"
            />
            </span>
            <span *ngIf="this.result.ultimo_download == null">
              <input class="form-control text-center"
                     formControlName="download"
                     placeholder="Não há registros"
            />
            </span>
          </div>
        </div>
        <br>
        <br>
      </div>
      <hr>
      <div class="card">
        <div class="card-body card1">
          <div class="row d-flex justify-content-center">
            <div class="col-sm-3">
              <div class="d-flex flex-column">
                <label>Grupo</label>
                <select class="form-control" 
                        formControlName="grupo"
                        required
                        (change)="validar()"
                        [(ngModel)] = "tipoDocSelect"
                        >
                        <option *ngFor="let doc of groups; let i = index" [ngValue]="doc">{{doc.descricao}}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label>Data Divulgação</label>
                <input  class="form-control text-center"
                        formControlName="dateStart"
                        id="dateStart"
                        name="dateStart"
                        required
                        placeholder="DD/MM/AAAA"
                        [(ngModel)] = "dateStartEdit"
                        (ngModelChange)="validarDtInicio(dateStartEdit)"
                        bsDatepicker
                        [bsConfig]="{
                          isAnimated: true,
                          adaptivePosition: true,
                          dateInputFormat: 'DD/MM/YYYY'
                        }"
                        (bsValueChange)="onChangeDatePickerStart($event)"
                        />
              </div>
            </div>
            <div class="col-sm-3 pull-right">
              <div class="form-group ">
                <label>Data Expiração</label>
                <input class="form-control text-center"
                       formControlName="dateEnd" 
                       id="dateEnd"
                       name="dateEnd"
                       placeholder="DD/MM/AAAA"
                       [(ngModel)] = "dateEndEdit"
                       (ngModelChange)="validarDtFim(dateEndEdit)"
                       bsDatepicker
                       [bsConfig]="{
                        isAnimated: true,
                        adaptivePosition: true,
                        dateInputFormat: 'DD/MM/YYYY'
                       }"
                       (bsValueChange)="onChangeDatePickerEnd($event)"
                       />
              </div>
            </div>
            <div class="col-sm-3 pull-right">
              <div class="form-group ">
                <label>Boletim</label>
                <input class="form-control text-left"
                       formControlName="boletim"
                       [(ngModel)]="result.numero_boletim"
                       (change)="validar()"/>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-sm-6 pull-right">
              <div class="form-group ">
                <label>Assunto</label>
                <input class="form-control text-left" 
                       formControlName="assunto"
                       required
                       [(ngModel)]="result.assunto"
                       (change)="validar()"/>
              </div>
            </div>
            <div class="col-sm-6 pull-right">
              <div class="form-group ">
                <label>Substituição</label>
                <input class="form-control text-left" 
                       formControlName="substituicao"
                       [(ngModel)]="result.substituicao"
                       (change)="validar()"/>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <h5 class="text-up" style="color: #94999D;background-color: #FAFAFA;">Extensões de Arquivos Permitidos : .doc, .pdf, .xlsx, .xls, .txt, .ppt</h5>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="btn-container mt-2">
              <a class="btn btn-primary cursor-pointer">
                  Escolher arquivo...
                  <input type="file" 
                         id="fileInput"
                         formControlName="arquivo"
                         (change)="addfile($event)"
                         class="cursor-pointer file-input" 
                         accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, 
                         application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf,
                         application/powerpoint, application/vnd.ms-powerpoint, text/plain, application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                         application/vnd.openxmlformats-officedocument.presentationml.presentation, application/zip" />
              </a>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <span class="label label-info mt-3" 
                    id="upload-file-info"
                    *ngIf="currentFile"> {{ currentFile }}
              </span>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="modal-footer">
        <div class="form-group">
          <button type="button" class="btn btn-danger pull-right btn-action" aria-label="Close" (click)="onClose()">
            Voltar
          </button>
          <button type="button" class="btn btn-primary pull-right btn-action" aria-label="Close" [disabled]="!this.habilitar" (click)="onEdit()">
            Incluir
          </button>
        </div>
</div>