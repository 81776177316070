<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-grid-pulse"
    [fullScreen]="true"
	#spinner
>
    <p style="color: white">Aguarde...</p>
</ngx-spinner>

<c-navigation id="menubody" navigation *ngIf="userAuthentication" [listMenus]="listMenus" >
</c-navigation>
<c-content class="dark" [ngClass]="{ 'user-authentication': userAuthentication }">
    <section class="dark ux-sections">
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </section>
</c-content>
