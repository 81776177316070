import { Component, OnInit } from '@angular/core';
import { ListDocumentsModel } from 'src/app/shared/models/list-documents.model';
import { DocumentsService } from 'src/app/shared/services/documents.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import Swal from 'sweetalert2';
import { UploadDocumentModalComponent } from '../upload-document-modal/upload-document-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocumentsModalEditComponent } from '../documents-modal-edit/documents-modal-edit.component';

@Component({
  selector: 'app-documents-modal',
  templateUrl: './documents-modal.component.html',
  styleUrls: ['./documents-modal.component.css']
})
export class DocumentsModalComponent implements OnInit {

  searchListing: string = '';
  pageUpload: number;
  orderBy = false;
  public modalRef?: BsModalRef;
  qtdeUltimosDias: number = 0;
  somenteVigentes: boolean = false;

  lastName: string;
  
  listDocumentsModel: ListDocumentsModel [] = [];

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private documentsService: DocumentsService,
              private globalService: GlobalService) 
  { }

  ngOnInit() {

    setTimeout(() => {
      this.listDocuments(this.qtdeUltimosDias, this.somenteVigentes)
    }, 200);

    this.documentsService.EmmitNameChange
    .subscribe((value: string) => {
      this.lastName = value;
      
       if(this.lastName != undefined) {      
        this.listDocuments(this.qtdeUltimosDias, this.somenteVigentes)  
      } 
    });
  }

    
  // fechar modal
  onClose(): void {
    this.bsModalRef.hide();
  }

  // filtro buscar
  onSearch(search: string): void {
    this.pageUpload = 1;
    this.searchListing = search;
  }

  // modal upload
  onUploadDocument(){
    this.modalRef = this.modalService.show(UploadDocumentModalComponent,{
      initialState:{

      },
      class: 'modal-lg'
    })
  }
  
  // modal de edicao
  onEditDocument(id: number){
    this.modalRef = this.modalService.show(DocumentsModalEditComponent, {
      initialState: {
        listaDocumentos: this.listDocumentsModel,
        id_doc : id,
        qtdeUltimosDias: this.qtdeUltimosDias,
        somenteVigentes: this.somenteVigentes
      },
      class:'modal-lg'
    });
  }

  // excluir linha 
  deleteLine(id: number) {

    Swal.fire({
      title: `Deseja realmente realizar a exclusão desse item?`,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#033a6a',
      cancelButtonColor: '#CC1040',
      allowOutsideClick: false,
    })
    .then((result) => {

      if (result.isConfirmed) {

        this.documentsService.deleteDocument(id)
          .subscribe(
            (result) => {
            
              if (result.content === true) {
             
                this.globalService.alert("Atenção", "Registro Excluido", "success");
                this.ngOnInit()
                const name = "deletar"
                this.documentsService.putDelete(name)
               
              } else {
                this.globalService.alert("Atenção", "Registro Não Excluído", "error")
               
              }
            }
          )
      }
    }
  )
  }

  //listagem 
  listDocuments(qtdeUltimosDias: number,somenteVigentes: boolean){
    this.documentsService.getDocumentList(qtdeUltimosDias, somenteVigentes)
    .subscribe(
      ret => {
        this.listDocumentsModel = ret.content; 
      }
    )
  }

  onDownloadFile(documento: ListDocumentsModel){

    this.documentsService.getContentDocument(documento.id_arquivo)
    .subscribe( 
      (data: any) => { 

              const arquivo = new  Uint8Array(atob(data.content).split('').map((char) => char.charCodeAt(0)));

              const blob = new Blob([arquivo],{ type: documento.tipo_conteudo }); 
              
              let dwldLink = document.createElement("a");
              let url = window.URL.createObjectURL(blob);
            
              dwldLink.setAttribute("href", url);
              dwldLink.setAttribute("download", documento.nome_arquivo);
              dwldLink.click();
              const name = "download"
              this.documentsService.putDownload(name)
              this.listDocuments(this.qtdeUltimosDias, this.somenteVigentes);
      } 
      ) 
  }

}
