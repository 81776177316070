import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterPipe } from './filter/filter.pipe';
import { FilterArrayPipe } from './filter/filter-array.pipe';

@NgModule({
    declarations: [FilterPipe, FilterArrayPipe],
    imports: [CommonModule],
    exports: [FilterPipe, FilterArrayPipe],
})
export class PipeModule {}
