import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProgramaManutencaoModel } from 'src/app/shared/models/programa-manutencao.model';
import { ProgramaManutencaoService } from 'src/app/shared/services/programa-manutencao.service';

export class ProgramaResult{
  sistematica:number
  pro_type:number;
  opr_type:number;
  produto_revisao:number;
  km_hora:string;
  ciclo:number;
  programaModel : ProgramaManutencaoModel [];
}

@Component({
  selector: 'app-programa-modal',
  templateUrl: './programa-modal.component.html',
  styleUrls: ['./programa-modal.component.scss']
})
export class ProgramaModalComponent implements OnInit {
  @Input() sistematica
  @Input() pro_type;
  @Input() opr_type;
  @Input() produto_revisao;
  @Input() km_hora;
  @Input() ciclo;
  programaModel : ProgramaManutencaoModel [];

  constructor(private modalRef: BsModalRef,
              protected programaManutencaoService: ProgramaManutencaoService) { }

  ngOnInit() {
    const button = document.getElementById('teste');
    button.click();
    button.setAttribute('hidden', 'true');
    
    this.getProgramaManutencao(this.sistematica, this.pro_type, this.opr_type, this.produto_revisao, this.km_hora, this.ciclo);
  }

  onClose(){
    this.modalRef.hide();
  }

  getProgramaManutencao(sistematica: number,
    pro_type: number,
    opr_type: number,
    produto_revisao: number,
    km_hora: string,
    ciclo:number) {
      this.programaManutencaoService.getProgramaManutencao(sistematica,pro_type,opr_type,produto_revisao,km_hora,ciclo).subscribe(
      result => {
         this.programaModel = result.content;
      }
  );
}
  consultar(){
    this.getProgramaManutencao(this.sistematica, this.pro_type, this.opr_type, this.produto_revisao, this.km_hora, this.ciclo);
  }
}
