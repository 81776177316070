<label class="switch" [ngClass]="{ disable: disabled }">
    <input
        type="checkbox"
        [disabled]="disabled"
        [(ngModel)]="checked"
        (ngModelChange)="onChange($event)"
        (blur)="onTouch()"
    />
    <span class="slider round"></span>
</label>
