import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterBy',
})
export class FilterPipe implements PipeTransform {
    transform(value: any, searchText: any): any {
        if (!searchText || value === undefined) {
            return value;
        }
        return value.filter((data) => this.matchValue(data, searchText));
    }

    matchValue(data, value) {
        const dt = data.value === undefined ? data : data.value;
        return Object.keys(dt)
            .map((key) => {
                return new RegExp(value, 'gi').test(dt[key]);
            })
            .some((result) => result);
    }
}
