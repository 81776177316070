import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CampanhaModel } from 'src/app/shared/models/campanha.model';
import { CampanhaService } from 'src/app/shared/services/campanha.service';

export  interface CampanhaResult {
  chassi: number;
  motor: number;
  casa: string;
}

@Component({
  selector: 'app-campanha-modal',
  templateUrl: './campanha-modal.component.html',
  styleUrls: ['./campanha-modal.component.scss']
})
export class CampanhaModalComponent implements OnInit {
  @Input() campanhaModel2;
  @Input() chassi2;
  @Input() casa2;
  chassi: number;
  motor: number;
  casa: string;
  campanhaModel: CampanhaModel [] = [];

  constructor(private bsModalRef: BsModalRef,
              protected campanhaService: CampanhaService) {}

  ngOnInit() {  }

  onClose(): void {
    this.bsModalRef.hide();
  }
}
