import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResultApi } from "../interfaces/envelopes.interface";
import { ServicoOpcionalModel } from "../models/servico-opcional.model";
import { DataService } from "./data.service";

@Injectable({
	providedIn: 'root',
})

export class ServicoOpcionalService {
    private url: string;
	constructor(private dataService: DataService) { }

	//https://acp-revisao-api.br.scania.com/api/ServicosOpcionais/Produto?produto_revisao=206521&sistematica=11&tipo_revisao=22&ciclo=1&seq=1
	getServicoOpcional(produto_revisao: number,
						sistematica: number,
						tipo_revisao: number,
						ciclo: number,
						seq: number) :Observable<ResultApi<ServicoOpcionalModel[]>> {

			this.url = `/ServicosOpcionais/Produto?produto_revisao=${produto_revisao}&sistematica=${sistematica}&tipo_revisao=${tipo_revisao}&ciclo=${ciclo}&seq=${seq}`;

			return this.dataService.get<ResultApi<ServicoOpcionalModel[]>>(this.url, null);
		}
}
