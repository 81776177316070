import { Component, OnInit } from '@angular/core';
import { DocumentsService } from 'src/app/shared/services/documents.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

    constructor(private documentsService: DocumentsService) {}

    ngOnInit() {
        const assunto = "consultar"
        this.documentsService.putUploadAll(assunto)
    }

}
