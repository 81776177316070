import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.css'],
})
export class TableFilterComponent {
    @Input() color = '#f5f5f5';
    @Input() placeholder = '';

    @Output() searchText = new EventEmitter();

    onChangeSearch(text: string): void {
        this.searchText.emit(text);
    }
}
