<!-- <div [formGroup]="form"> -->
<div>
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalScrollableTitle">Filiais</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <!-- (click)="onClose()" -->
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body ">
      <div [formGroup]="form">
        <div class="row d-flex justify-content-center">
          <div class="col-10" style="margin-bottom: -1rem!important;" *ngIf="action == 'U'; else incluindo">
            <table class="table table-condensed table-sm table-borderless mb-3"
              style="border: 1px solid black!important;margin-bottom:-3rem!important;">
              <thead>
                <tr class="font">
                  <th class="text-left">
                    Nome
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    XDS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ usuario | titlecase }}</td>
                  <td class="font-weight-bold">{{ email | lowercase }}</td>
                  <td class="font-weight-bold text-danger"> XDS: {{ userLogin | uppercase }}</td>
                </tr>
              </tbody>
            </table>
            <br>
            <div class="row d-flex justify-content-center mb-3" *ngIf="editScania">
              <div class="col-6" style="margin-top: 2rem; margin-bottom: -1rem; width:1200px">
                <label>Buscar</label>
                <app-table-filter (searchText)="onSearch($event)" color="#dee2e6"></app-table-filter>
              </div>
            </div>
          </div>
          <ng-template #incluindo>
            <div class="row">
              <div class="col-4 input-group mb-3" style="width:400px">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Nome</span>
                </div>
                <input type="text" formControlName="nome" class="form-control" placeholder="Nome do usuário"
                  maxlength="200" aria-label="Username" aria-describedby="basic-addon1">
              </div>

              <div class="col-5 input-group mb-3">
                <input type="text" class="form-control text-lowercase" formControlName="email" maxlength="200"
                  placeholder="E-mail" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">email</span>
                </div>
              </div>
              <div class="col-3 input-group mb-3">
                <input type="text" formControlName="xds" maxlength="6" class="form-control text-uppercase"
                  placeholder="novo XDS" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">XDS</span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="col-7 input-group mt-3">
                <select class="form-control form-control-md" formControlName="tipo" [(ngModel)]="tipoSelecionado.id" (change)="onSelect($event.target.value)"
                   >
                  <option *ngFor="let tipo of tipos" value={{tipo.id}}>
                    {{tipo.nome}}
                  </option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">Tipo</span>
                </div>
              </div>
              <div class="col-7 input-group mt-3">
                <select class="form-control form-control-md" formControlName="mercado" [(ngModel)]="selectedValue" (change)="ListaCeLB($event.target.value)"
                  >
                  <!--   <option value="0">--Selecione o Tipo--</option> -->
                  <option *ngFor="let mercado of mercados" value={{mercado.id}} > {{mercado.nome}} </option>
                </select>
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">Mercado</span>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center" *ngIf="editScania">
              <div class="col-6" style="margin-top: 2rem; margin-bottom: -1rem; width:1000px">
                <label>Buscar</label>
                <app-table-filter (searchText)="onSearch($event)" color="#dee2e6"></app-table-filter>
              </div>
            </div>

          </ng-template>
        </div>

      </div>
      <hr>
      <div class="row d-flex justify-content-center" *ngIf="editScania">
        <div class="col-12" *ngIf="editScania">
          <div class="row">
            <div class="col-6">
              <h6 class="text-success">Concessionárias sem acesso</h6>
              <div class="table-responsive" style="overflow: auto; height: 280px; width: 100%">
                <table class="table">
                  <thead>
                    <tr class="font">
                      <th class="text-left" colspan="1">
                        Razão Social
                      </th>
                      <!-- <th class="text-center ">
      ,
                            </th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let conc of concessionarias
                                                | filterBy: searchUser
                                                | paginate: { itemsPerPage: 500, currentPage: p };
                                                  let i = index">
                      <div *ngIf="conc.checado != true">
                        <tr class="font">
                          <ng-container *ngIf="conc.identificacao_responsavel === 'M'; else filial2">
                            <td class="text-left text-danger">
                              {{conc.codigo}} - {{conc.razaoSocial}}
                            </td>
                          </ng-container>
                          <ng-template #filial2>
                            <td class="col-10 text-left">
                              {{conc.codigo}} - {{conc.razaoSocial}}
                            </td>
                          </ng-template>
                          <!--
                                  <td class="text-left tdT">

                                    {{conc.codconcessionario}} - {{conc.razaoconcessionario}}
                                  </td> -->
                          <td class="col-2 text-center" (click)="manager(conc.codigo,true)">
                            <img class='setadireita' title="Adicionar Acesso"
                              src="../../../../assets/images/setagreen2.png" />
                          </td>
                        </tr>

                      </div>

                    </ng-container>

                    <!-- (change)="onChange($event.target.checked, conc.codconcessionario, $event)" -->
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-6">
              <h6 class="text-danger">Concessionárias com acesso</h6>
              <div class="table-responsive" style="overflow: auto; height: 280px; width: 100%">
                <table class="table">
                  <thead>
                    <tr class="font">
                      <th class="text-left thTable" colspan="1">
                        Razão Social
                        <!-- <i class="bi bi-arrow-down-up"></i> -->
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let conc of concessionarias
                                | filterBy: searchUser
                                | paginate: { itemsPerPage: 500, currentPage: p };
                                  let i = index">
                      <!-- {{conc.checado}} - {{conc.codconcessionario}} - {{conc.razaoconcessionario}} -->
                      <div *ngIf="conc.checado == true">

                        <tr class="font">

                          <td class="col-2 text-center" (click)="manager(conc.codigo,false)">
                            <img class='setasquerda' title="Remover acesso"
                              src="../../../../assets/images/setared.png" />
                          </td>

                          <ng-container *ngIf="conc.identificacao_responsavel === 'M'; else filial1">
                            <td class="text-left text-danger">
                              {{conc.codigo}} - {{conc.razaoSocial}}
                            </td>
                          </ng-container>
                          <ng-template #filial1>
                            <td class="col-10 text-left">
                              {{conc.codigo}} - {{conc.razaoSocial}}
                            </td>
                          </ng-template>


                          <!-- <td class="col-10 text-left ">
                                    {{conc.codconcessionario}} - {{conc.razaoconcessionario}}
                                  </td> -->
                        </tr>
                      </div>

                    </ng-container>

                  </tbody>
                </table>

              </div>
            </div>
            <br><br>
          </div>


        </div>
      </div>
      <div *ngIf="!editScania">
        <h6 class="d-flex justify-content-center">Para os administradores Scania/Usuarios Scania não é necessario que o usuario seja vinculado a filiais.</h6>
      </div>
    </div>
    <div class="modal-footer">
      <ng-container *ngIf="action == 'U'; else inc">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="save2()"  [disabled]="!editScania">
          Alterar
        </button>
      </ng-container>

      <ng-template #inc>
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="save2()" [disabled]="!form.valid">
          Incluir
        </button>
      </ng-template>

      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onClose()">
        Voltar
      </button>
    </div>

    <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->

  </div>
<!-- </div> -->