import { Component, Input, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserAccessService } from 'src/app/shared/services/user-manager.service';
import { UserModel } from '../../../models/user-list.model';
import { Tipo } from '../permissons-filial/tipo';
import { Mercado } from '../permissons-filial/mercado';
import { GlobalService } from 'src/app/shared/services/global.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UserFGModel } from 'src/app/shared/models/userfg.model';
import { BsModalService } from 'ngx-bootstrap/modal';

export class UserXds {
	UserLogged: string;
	UserCoresnet: string;
	UserXds: string;
	EMail: string;
	tipo: number;
	mu: string;
	nomeCompleto: string;
	dtExpiracao: String;
}

@Component({
	selector: 'app-edit-xds-modal',
	templateUrl: './edit-xds-modal.component.html',
	styleUrls: ['./edit-xds-modal.component.scss'],
})
export class EditXdsModalComponent implements OnInit {
	@Input() usuario: string;
	@Input() e_mail: string;
	@Input() nome_completo: string;
	@Input() cod_conc: number;
	@Input() user_login: string;
	@Input() tipo: number;
	@Input() mu: string;
  @Input() cod_cargo: any;

	dtexpiracao: string;
	tipos: Tipo[];
	mercados: Mercado[];
	tipoSelecionado: Tipo = new Tipo(0, '--Selecione--');
	tipoMercado: boolean;

	selectedValue: number;
	userFG: UserFGModel; //Federation Gateway
	listOfUsers: UserModel[] = [];
	public userXds: string | null = null;

	isDuplic: boolean = false;
	form: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private userAccessService: UserAccessService,
		private globalService: GlobalService,
		private userService: UserService,
		private modalService: BsModalService
	) {
		this.form = this.formBuilder.group({
			usuario: null,
			nome_completo: null,
			e_mail: [
				null,
				[
					Validators.required,
					Validators.email,
					Validators.minLength(6),
				],
			],
			user_login: [null, Validators.required],
			dateStart: null,
			tipo: null,
			mu: null,
		});
	}

	ngOnInit() {
		// if (this.dtexpiracao == '0001-01-01'){
		//   this.dtexpiracao = null;
		//   this.form.patchValue({
		//     nome_completo: this.nome_completo,
		//     e_mail: this.e_mail,
		//     user_login: this.user_login,
		//     dateStart: null
		//   })
		// }
		// else{
		//   this.dtexpiracao = this.dtexpiracao + ' 13:00';
		//   this.form.patchValue({
		//     nome_completo: this.nome_completo,
		//     e_mail: this.e_mail,
		//     user_login: this.user_login,
		//     dateStart: new Date(this.dtexpiracao)
    // }) }
    this.form.get('user_login').disable();
		this.tipoSelecionado.id = this.tipo;
		this.mercados = this.globalService
			.getMercado()
			.filter((item) => item.tipoid == this.tipoSelecionado.id);
		if (this.mu == 'LB') {
			this.selectedValue = 1;
		}

		if (this.mu == 'CE') {
			this.selectedValue = 2;
		}

		let user = this.userService.getUserFG().subscribe((dealer) => {
			this.userFG = dealer;

			if (this.userFG.tipo == 1) {
				this.tipos = this.globalService.getTipos();
				this.tipoMercado = false;
			}

			if (this.userFG.tipo == 2) {
				this.tipos = this.globalService.getTipos2();
				this.tipoMercado = true;
			}

			this.userAccessService
				.getUserAccessListUser(this.userFG.xds)
				.subscribe((listuser) => {
					this.listOfUsers = listuser;
				});
		});

		this.userXds = this.authService.getXdsUser().toUpperCase();

		this.form.patchValue({
			usuario: this.usuario,
			nome_completo: this.nome_completo,
			e_mail: this.e_mail,
			user_login: this.user_login,
			tipo: this.tipo,
			mu: this.mu,
		});
	}

	saveXds(): void {
		let userXdsalt = new UserXds();
		let user = this.authService.getUserData();
		userXdsalt.EMail = this.form.get('e_mail').value;
		userXdsalt.UserXds = this.form.get('user_login').value.toUpperCase();
		userXdsalt.nomeCompleto = this.form
			.get('nome_completo')
			.value.toUpperCase();
		userXdsalt.UserLogged = user.wam_user.xds.toUpperCase();
		userXdsalt.tipo = this.form.get('tipo').value;
		userXdsalt.mu = this.form.get('mu').value;

		if (userXdsalt.mu == 'LB') {
			userXdsalt.mu = 'LB';
		}

		if (userXdsalt.mu == 'CE') {
			userXdsalt.mu = 'CE';
		}
		userXdsalt.UserLogged = user.wam_user.xds.toUpperCase();

		this.userAccessService.postUserXDS(userXdsalt).subscribe((ret) => {
			if (ret.tipomessage === 'success') {
				this.globalService.alert(ret.msg, ret.msg, 'success');
				setTimeout(() => {
					window.location.reload();
				}, 600);
					this.closeModal();
			}
			else this.globalService.alert(ret.msg, ret.msg, 'error');			
		});
	}

	closeModal(modalId?: number) {
		this.modalService.hide(modalId);
	}

	onSelect(id) {
		if (this.tipoMercado == false) {
			this.mercados = this.globalService
				.getMercado()
				.filter((item) => item.tipoid == id);
		}

		if (this.tipoMercado == true) {
			this.mercados = this.globalService
				.getMercado2()
				.filter((item) => item.tipoid == id);
		}
	}
}
