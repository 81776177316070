<div backdrop="static" keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-header">
    <h4 class="modal-title">
      Upload de Arquivo
    </h4>
  </div>
  <div class="modal-body rolagem ml-5 mr-5">
    <div class="row">
      <div class="col-4">
        <app-table-filter (searchText)="onSearch($event)" placeholder="Buscar ..." color="#dee2e6">
        </app-table-filter>
      </div>
      <div class="col-8">
        <h5 class="pull-right">Upload
          <button class="btn btn-primary btn-inline btn-sm px-2 py-1 ml-2" title="Upload de Arquivo"
            data-toggle="tooltip"
            (click)="onUploadDocument()">            
            <i class="bi bi-file-earmark-arrow-up btn-icon"></i>
          </button>
        </h5>
      </div>
    </div>
    <table class="table table-bordered table-sm mb-0 lines">
      <thead>
        <tr>
          <th colspan="2" class="bg-blue text-center text-upper">
            Arquivo
          </th>
          <th colspan="2" class="bg-blue text-center text-upper">
            Assunto
          </th>
          <th colspan="2" class="bg-blue text-center text-upper">
            Substituição
          </th>
          <th colspan="1" class="text-center">
            Dt Envio
          </th>
          <th colspan="1" class="text-center">
            Grupo
          </th>
          <th colspan="1" class="text-center" colspan="1.5">
            Dt Divulgação
          </th>
          <th colspan="1" class="text-center" colspan="1">
            Dt Expiração
          </th>
          <th colspan="1" class="bg-blue text-center text-upper">
            Boletim
          </th>
          <th colspan="1" class="text-center">
            Ações
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let docs of listDocumentsModel 
                            | filterBy: searchListing
                            | paginate
                                : {
                                      id: 'pgcUpload',
                                      itemsPerPage: 5,
                                      currentPage: pageUpload
                                  };
                        let i = index">
          <td class="text-center" colspan="2">
            <a class="azul" data-toggle="tab" href="#" role="tab" aria-selected="true" (click)="onDownloadFile(docs)">
              {{docs.nome_arquivo}}
            </a>
          </td>
          <td colspan="2" class="text-center">
            {{docs.assunto}}
          </td>
          <td colspan="2" class="text-center">
            {{docs.substituicao}}
          </td>
          <td colspan="1" class="text-center">
            {{docs.data_inclusao | date: 'shortDate'}}
          </td>
          <td colspan="1" class="text-center">
            {{docs.tipo_arquivo}}
          </td>
          <td colspan="1" class="text-center">
            {{docs.dt_inicio_vigencia | date: 'shortDate'}}
          </td>
          <td colspan="1" class="text-center">
            {{docs.dt_final_vigencia | date: 'shortDate'}}
          </td>
          <td colspan="1" class="text-center">
            {{docs.numero_boletim}}
          </td>
          <td class="text-center" colspan="1">
            <button class="
                      btn btn-success btn-inline btn-sm
                      py-0
                      pl-0
                      pr-0
                      mr-3" title="Editar" data-toggle="tooltip" (click)="onEditDocument(docs.id_arquivo)">
              <i class="bi bi-pencil-square btn-icon"></i>
            </button>
            <button class="
                            btn btn-danger btn-inline btn-sm
                            py-0
                            pl-0
                            pr-0
                            mr-0" title="Excluir" data-toggle="tooltip" (click)="deleteLine(docs.id_arquivo)">
              <i class="bi bi-trash btn-icon"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <br>
    <hr>
    <div class="row d-flex justify-content-between">
      <div class="col paginator">
        <label>Total
          <span class="badge badge-default mr-2">
            {{listDocumentsModel.length}}
          </span>linhas
        </label>
      </div>
      <div>
        <pagination-controls id="pgcUpload" previousLabel="Anterior" responsive="true" nextLabel="Seguinte" maxSize="10"
          autoHide="true" (pageChange)="pageUpload = $event">
        </pagination-controls>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group">
      <button type="button" class="btn btn-danger pull-right btn-action" aria-label="Close" (click)="onClose()">  
        Voltar
      </button>
    </div>
  </div>