import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { result } from 'underscore';
import { Mercado } from '../components/modals/permissons-filial/mercado';
import { Tipo } from '../components/modals/permissons-filial/tipo';

@Injectable({
	providedIn: 'root',
})
export class GlobalService {

	getTipos() {
		return [
		new Tipo(0, '--Selecione--' ),
		new Tipo(1, 'Administrador Scania' ),
		new Tipo(2, 'Administrador' ),
		new Tipo(3, 'Usuário' ),
		  new Tipo(4, 'Usuário Scania' )
		];
	}

	getTipos2() {
		return [
		new Tipo(0, '--Selecione--' ),
		new Tipo(2, 'Administrador' ),
		new Tipo(3, 'Usuário' ),
		];
	}

	getMercado() {
		return [
		new Mercado(0, 0, '', '--Selecione o Tipo--' ),
		new Mercado(1, 1, 'LB', 'Brasil' ),
		new Mercado(2, 1, 'CE', 'Cliente Especial' ),
		new Mercado(1, 3, 'LB','Brasil'),
		new Mercado(2, 3, 'CE', 'Cliente Especial'),
		new Mercado(1, 2, 'LB','Brasil' ),
		new Mercado(1, 4, 'LB','Brasil'),
		new Mercado(2, 4, 'CE', 'Cliente Especial'),
		];
	}

	getMercado2() {
		return [
		new Mercado(0, 0, '', '--Selecione o Tipo--' ),
		new Mercado(1, 2, 'LB', 'Brasil' ),
		new Mercado(1, 3, 'LB', 'Brasil' ),
		new Mercado(1, 4, 'LB', 'Brasil' ),

		];
	}

	alert(title: string, message: string, icon): void {
		Swal.fire({
			title: title,
			text: message,
			icon: icon,  // 'success' | 'error' | 'warning' | 'info' | 'question';
			showConfirmButton: true,
			showCancelButton: false,
			cancelButtonText: 'Ok',
			cancelButtonColor: '#CC1040',
			allowOutsideClick: false,
		});
	}

	alertConfirm(title: string, message: string, icon: any = 'info', options: {} | null = null): boolean {
		let confirmado: boolean = false;
		let options_default = {
			title: title,
			text: message,
			icon: icon,  // 'success' | 'error' | 'warning' | 'info' | 'question';
			showConfirmButton: true,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			cancelButtonColor: '#CC1040',
			confirmButtonText: 'Confirma',
			confirmButtonColor: '#008000',
			allowOutsideClick: false,
		}
		let options_parse = options_default;
		if(options) {
			options_parse = Object.assign({}, options, options_default);
		}
		Swal.fire(options_parse).then((result) => {
			confirmado = result.isConfirmed
		});

		return confirmado;
	}

	formatPrice(price: number): string {
		let valor = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(price);
		if (price === null ){
			valor = "R$ 0.00"
		}
		return valor
	}

	formatDecimal(price: number, qtd: number = 1): string {
		return price.toFixed(qtd);
	}

	onlyDecimal(event) {
		return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46);
	}

	onlyNumber(event) {
		return ((event.charCode >= 48 && event.charCode <= 57));
	}

	validaCnpj(cnpj):boolean{
		cnpj = cnpj.replace(/[^\d]+/g,'');
		cnpj = this.strZero(cnpj,14);
	  if (cnpj == '') return false;
	  if (cnpj.length != 14)
		  return false;
	  // LINHA 10 - Elimina CNPJs invalidos conhecidos
	  if (cnpj == "00000000000000" ||
		  cnpj == "11111111111111" ||
		  cnpj == "22222222222222" ||
		  cnpj == "33333333333333" ||
		  cnpj == "44444444444444" ||
		  cnpj == "55555555555555" ||
		  cnpj == "66666666666666" ||
		  cnpj == "77777777777777" ||
		  cnpj == "88888888888888" ||
		  cnpj == "99999999999999")
		  return false; // LINHA 21

	  // Valida DVs LINHA 23 -
	  let tamanho = cnpj.length - 2
	  let numeros = cnpj.substring(0,tamanho);
	  let digitos = cnpj.substring(tamanho);
	  let soma = 0;
	  let pos = tamanho - 7;
	  for (let i = tamanho; i >= 1; i--) {
		  soma += numeros.charAt(tamanho - i) * pos--;
		  if (pos < 2)
			  pos = 9;
	  }
	  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	  if (resultado != digitos.charAt(0))
		  return false;

	  tamanho = tamanho + 1;
	  numeros = cnpj.substring(0,tamanho);
	  soma = 0;
	  pos = tamanho - 7;
	  for (let i = tamanho; i >= 1; i--) {
		  soma += numeros.charAt(tamanho - i) * pos--;
		  if (pos < 2)
			  pos = 9;
	  }
	  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	  if (resultado != digitos.charAt(1))
			return false; // LINHA 49

	  return true; // LINHA 51

	}

	validaCPF(cpf):boolean{
		let Soma = 0;
		// Verifica se a variável cpf é igual a "undefined", exibindo uma msg de erro
		if (cpf === undefined) {
		  return false;
		}

		// Esta função retira os caracteres . e - da string do cpf, deixando apenas os números
		var strCPF = cpf.replace('.', '').replace('.', '').replace('-', '');
		strCPF = this.strZero(strCPF,11);
		// Testa as sequencias que possuem todos os dígitos iguais e, se o cpf não tem 11 dígitos, retorna falso e exibe uma msg de erro
		if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' ||
		strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' || strCPF === '88888888888' ||
		strCPF === '99999999999' || strCPF.length !== 11)
		  return false;

		  for (let i= 1; i <= 9; i++) {
			Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
		  }

		  var Resto = (Soma * 10) % 11;
		  if ((Resto === 10) || (Resto === 11)) {
			Resto = 0;
		  }

		  if (Resto !== parseInt(strCPF.substring(9, 10))) {
			return false;
		  }

		  Soma = 0;
		  for (let k = 1; k <= 10; k++) {
			Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k)
		  }

		  Resto = (Soma * 10) % 11;
		  if ((Resto === 10) || (Resto === 11)) {
			Resto = 0;
		  }

		  if (Resto !== parseInt(strCPF.substring(10, 11))) {
			return false;
		  }
		  return true;

	}
	strZero(numero:string, tamanho:number): string {
        while (numero.length < tamanho) {
           numero =  '0' + numero;
		}
		return numero;
	}

	public addZeroes(num:any, len:number) :string {
		var numberWithZeroes = String(num);
	  var counter = numberWithZeroes.length;

	  while(counter < len) {

		  numberWithZeroes = "0" + numberWithZeroes;

		counter++;

		}

	  return numberWithZeroes;
	}
}
