
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UserModel } from '../models/user-list.model';
import { UserAccessControlModel } from '../models/user-access.model';
import { UserAccessDelModel } from '../models/user-access-del.model';
import { SetAccessResultModel } from '../models/user-setaccessresult.model';
import { deleteUser } from '../components/modals/delete-permission-xds-modal/delete-permission-xds-modal.component';
import { deleteUserBase } from '../models/delete-user-base';


@Injectable({
  providedIn: 'root',
})

export class UserAccessService {
  private url: string = '';

  constructor(private dataService: DataService) { }

  postNewUser(userXds: any) : Observable<any> {
		this.url = `/UserManager/InsUserInformation`;
		return this.dataService.post(this.url, userXds);
	}


  getUserAccessList(codconc: number): Observable<[UserModel]> {
    this.url = `/UserManager/AllUserAccessControl?dealerCode=${codconc}`
    return this.dataService.get<[UserModel]>(this.url, null);
  }

  getUserAccessListUser(usuario: string): Observable<[UserModel]> {
    this.url = `/UserManager/AllUserAccessControl?UserXds=${usuario}`
    return this.dataService.get<[UserModel]>(this.url, null);
  }

  getUserAccessMenu(userxds: string): Observable<[UserAccessControlModel]> {
    this.url = `/UserManager/UserAccessControl?UserXds=${userxds}`
    return this.dataService.get<[UserAccessControlModel]>(this.url, null);
  }

  getSecurityRoute(access: string){
    // access = access.replace('/','%2F');
    this.url = `/UserManager/SecurityRoute?Route=${access}`
    return this.dataService.get(this.url, null);
  }

  deleteUserAccessBySystemId(user: UserAccessDelModel){
    this.url = `/UserManager/DelUserAccessByUserXds?UserXds=${user.xdsUser}`;
    return this.dataService.delete(this.url);
  }

  postUserAccess(setUserAccess: any):Observable<SetAccessResultModel>{
    this.url = `/UserManager/SetUserAccess`;
    return this.dataService.post<SetAccessResultModel>(this.url,setUserAccess);
  }

  postUserXDS(userXds: any):Observable<any>{
    this.url = `/UserManager/UpdUserInformation`;
    return this.dataService.post<any>(this.url,userXds);
  }
  delUserInformation(userXds: deleteUser) :  Observable<deleteUserBase>{
	this.url = `/UserManager/DelUserInformation`;
	return this.dataService.deleteBodyJson<deleteUser>(this.url,userXds);
  }
  patchAlteraMu(usuarioLogado: string, muDesejado: string) {
	this.url = `/UserManager/AlteraMuUsuarioLogado?usuarioLogado=${usuarioLogado}&muDesejado=${muDesejado}`
	return this.dataService.patch(this.url, null);
  }
}


