import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResultApi } from "../interfaces/envelopes.interface";
import { ProgramaManutencaoModel } from "../models/programa-manutencao.model";
import { DataService } from "./data.service";

@Injectable({
	providedIn: 'root',
})

export class ProgramaManutencaoService {
    private url: string;
	constructor(private dataService: DataService) { }

//	https://acp-revisao-api.br.scania.com/api/Revisao/ProgramaDeManutencao?sistematica=11&pro_type=2&opr_type=9&produto_revisao=206521&km_hora=K&ciclo=1
	getProgramaManutencao(	sistematica: number,
							pro_type: number,
							opr_type: number,
							produto_revisao: number,
							km_hora: string,
							ciclo:number) :Observable<ResultApi<ProgramaManutencaoModel[]>> {

			this.url = `/Revisao/ProgramaDeManutencao?sistematica=${sistematica}&pro_type=${pro_type}&opr_type=${opr_type}&produto_revisao=${produto_revisao}&km_hora=${km_hora}&ciclo=${ciclo}`;

			return this.dataService.get<ResultApi<ProgramaManutencaoModel[]>>(this.url, null);
		}
}
