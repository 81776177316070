import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { delay, map, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MenuModel } from '../models/menu.model';
import { Result } from '../interfaces/envelopes.interface';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
	providedIn: 'root',
})

export class MenuService {
	constructor(private dataService: DataService) { }

	getMenu(userLogin: string, systemId: string):Observable<Result<MenuModel[]>> {
		return this.dataService.get<Result<MenuModel[]>>(`/Menu?UserLogin=${userLogin.toUpperCase()}`, null);
	}
}
