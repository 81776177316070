import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { result } from 'underscore';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { UserAccessService } from '../services/user-manager.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private authService: AuthService, private router: Router, private userAccessService: UserAccessService,
                private globalService: GlobalService) {}

    private checkAuthentication(): Observable<boolean> {
        return combineLatest([
            this.authService.checkAuthentication(),
            this.authService.checkAuthenticationCallback(),
        ]).pipe(
            take(1),
            map(([a, b]) => {
                if (!a && !b) {
                    this.authService.redirectLoginPage();
                }

                return a;
            })
        );
    }


    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        // router: Router
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        let auth =  this.checkAuthentication();
        //consistir rota depois que o cadastro de usuário pronto 
        if ((state.url.toString() != '/documents') && (state.url.toString() != '/boletim-circular') && (state.url.toString() != '/home') && (state.url.toString() != '/revisoes') && (state.url.substring(0,28) != '/revisoes/AtendimentoDetalhe') && (state.url.substring(0,18) != '/revisoes/Cadastro')){
            this.userAccessService.getSecurityRoute(state.url).subscribe(
                result => {
                    if (result.access === true)
                       return auth; 
                    else{
                        this.router.navigate(['/']);
                        setTimeout(() => {
                            this.globalService.alert('Contate o administrador','Você não tem acesso a essa página!', 'info');    
                        }, 300);
                        return false; 
                        // return auth; 
                    }
                      
                });    
        }
        return auth;
    }   

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuthentication();
    }
}
