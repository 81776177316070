import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResultApi } from "../interfaces/envelopes.interface";
import { CampanhaModel } from "../models/campanha.model";
import { DataService } from "./data.service";

@Injectable({
	providedIn: 'root',
})

export class CampanhaService {
    private url: string;
	constructor(private dataService: DataService) { }

//	https://acp-revisao-api.br.scania.com/api/Revisao/CampanhasProdutoByChassiMotor?chassi=3648116
//  https://acp-revisao-api.br.scania.com/api/Revisao/CampanhasProdutoByChassiMotor?chassi=3648116&motor=8129869


	getCampanha(chassi: number, motor: number):Observable<ResultApi<CampanhaModel[]>> {

		if (chassi != 0)
		{
			this.url = `/Revisao/CampanhasProdutoByChassiMotor?chassi=${chassi}`;
		}
		if (motor != 0)
		{
			this.url = `/Revisao/CampanhasProdutoByChassiMotor?motor=${motor}`;
		}
		if ((chassi != 0) && (motor !=0))
		{
			this.url = `/Revisao/CampanhasProdutoByChassiMotor?chassi=${chassi}&motor=${motor}`;

		}
		return this.dataService.get<ResultApi<CampanhaModel[]>>(this.url, null);
	}
}