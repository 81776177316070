export class RevisaoIncModel
{
    produto_revisao: number;
    sistematica: number;
    tipo_revisao: number;
    serie_produto: number;
    pro_type: number;
    opr_type: number;
    mks_code: number;
    tipo_comercializacao: number;
    dt_revisao: Date;
    dt_nota_fiscal: Date;
    kilometragem: number;
    horas_motor: number;
    id_ordem_servico: string;
    concessionaria: number;
    codigo: number;
    transacao: string;
    chassi: number;
    motor:number;
    kmhora:string;
    ciclo:number;
    seq:number;
  }