import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentGroupsModel } from 'src/app/shared/models/document-groups.model';
import { DocumentsService } from 'src/app/shared/services/documents.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.css']
})
export class UploadDocumentModalComponent implements OnInit {

  formUpload: FormGroup;

  dateStartString: string;
  dateEndString: string;

  currentFile: any;
  private file: File;

  dateStart: string;
  dateEnd: string;

  // listDocumentsModel: ListDocumentsModel[] = [];
   groups: DocumentGroupsModel[] = [];
  // group: DocumentGroupsModel;
  idGrupo: string = ''
  
  constructor(private bsModalRef: BsModalRef,
              private formBuilder: FormBuilder,
              private globalService: GlobalService,
              private documentsService: DocumentsService) 
  { 
    // forms
    this.formUpload = this.formBuilder.group({
      dateStart: [this.dateStartString, Validators.required],
      dateEnd: [this.dateEndString],
      grupo: [null, Validators.required],
      assunto: [null, Validators.required],
      boletim: [null, Validators.required],
      arquivo: [null, Validators.required],
      substituicao: [null],
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.listDocumentsGroups(this.idGrupo);
    }, 200);
  }

  // fechar modal
  onClose(): void {
    this.bsModalRef.hide();
  }

  // conversao de datas
  onChangeDatePickerStart(value: string): void {
    this.dateStartString = new Date(value).toISOString().slice(0, 10);
  }

  onChangeDatePickerEnd(value: string): void {
    this.dateEndString = new Date(value).toISOString().slice(0, 10);
  }

  
  // adicionar arquivo
  addfile(event: any): void {
    try {

      this.currentFile = event.target.files[0].name;
      this.file = event.target.files[0];
     
    } catch (error) { }
  }

  // listagem grupos
  listDocumentsGroups(idGrupo: string){
    this.documentsService.getDocumentGroup(idGrupo)
    .subscribe(
      ret => {
        this.groups = ret.content; 
      }
    )
  }

  onUpload() {
    if(this.file) {

      let assunto = this.formUpload.get('assunto').value;
      this.dateStart = this.dateStartString;
      this.dateEnd = this.dateEndString;
      let boletim = this.formUpload.get('boletim').value;
      let substituicao = this.formUpload.get('substituicao').value;

      let grupo = this.formUpload.get('grupo').value;

      var data = new Date();
      var dia = String(data.getDate()).padStart(2, '0');
      var mes = String(data.getMonth() + 1).padStart(2, '0');
      var ano = data.getFullYear();
      const dataAtual = ano + '-' + mes + '-' + dia;

      if(this.dateStart < dataAtual){
        this.globalService.alert("Atenção", "A data de divulgação deve ser igual ou superior a data de hoje", "info");
        return;
      }

      if (this.dateEnd === "1970-01-01") {
        
        this.documentsService.setDocumentsDateEnd(this.file, assunto, this.dateStart, grupo, boletim, substituicao)
        .subscribe(
        ret => {

          if(ret.tipomessage == 'success') {

            Swal.fire({
              title: 'Upload realizado com sucesso',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#033a6a',
              allowOutsideClick: false,
            });

            const name = "alterar"
            this.documentsService.putChange(name)
            this.documentsService.putUpload(assunto)
            this.bsModalRef.hide();
          }

          else {
            this.globalService.alert("Erro", "O arquivo selecionado possui um tamanho acima do suportado - 2MB", "error"); 
            return;
          } 
        }
      )
      } else {

        if (this.dateStart > this.dateEnd) {
          this.globalService.alert("Atenção", "Digite uma data de divulgação menor que a data expiração", "info");
          return;
        }
        this.documentsService.setDocuments(this.file, assunto, this.dateStart, this.dateEnd, grupo, boletim, substituicao)
        .subscribe(
          ret => {
            if(ret.tipomessage == 'success') {
  
              Swal.fire({
                title: 'Upload realizado com sucesso',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'Ok',
                confirmButtonColor: '#033a6a',
                allowOutsideClick: false,
              });
  
              const name = "alterar"
              this.documentsService.putChange(name)
              this.documentsService.putUpload(assunto)
              this.bsModalRef.hide();
            }
  
            else {
              this.globalService.alert("Erro", "O arquivo selecionado possui um tamanho acima do suportado -2MB", "error"); 
              return;
            } 
          }
        )
      }
    }
  }
  
}
