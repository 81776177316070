import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-login-callback',
    templateUrl: './login-callback.component.html',
    styleUrls: ['./login-callback.component.css'],
})
export class LoginCallbackComponent implements OnDestroy {
    private subscription: Subscription[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {
       // this.spinner.show('callback');

        this.subscription.push(
            this.activatedRoute.queryParams
                .pipe(
                    map((params) => params['code']),
                    switchMap((code) => this.authService.getToken(code)),
                    map((token) => this.authService.setToken(token))
                )
                .subscribe(() => this.router.navigate(['/home']))
        );
    }

    ngOnDestroy(): void {
        this.subscription.forEach((value) => value.unsubscribe());
    }
}
