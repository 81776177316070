import { IEnvironment } from './environment.interface';

export const federationGateway = {
    consumerKey: 'sla_services_delivery_it_prod',
    baseUrl: 'https://fg.ciam.prod.aws.scania.com',
    redirectUri: 'https://revisoes.scania.com.br/callback',
    authURL: 'auth/realms/scania/protocol/openid-connect',
    authorizerUri:
        'https://hubservices-api-custom-authorizer-eu-west-1-prd.sla02.prod.aws.scania.com',
};

export const environment: IEnvironment = {
    logLevel: 'info',

    production: true,
    enableDebugTools: false,

    app: {
        data_env: 'PROD',
        qnt_max_chassi: 25,
        // systemId: 2
    },

    wso2: {
        tokenUri: `${federationGateway.authorizerUri}/api/token`,
        authUri: `${federationGateway.baseUrl}/${federationGateway.authURL}/auth?client_id=${federationGateway.consumerKey}&response_type=code&scope=openid&redirect_uri=${federationGateway.redirectUri}&nonce=foo`,
        consumerKey: federationGateway.consumerKey,
        redirectUri: federationGateway.redirectUri,
        refrehTokenUri: `${federationGateway.authorizerUri}/api/refreshtoken`,
    },

    baseUrl: {
        api: 'https://apim-br.prod.aws.scania.com/revisao/v1/api'
    },
};
