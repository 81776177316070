import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ServicoOpcionalModel } from 'src/app/shared/models/servico-opcional.model';
import { ServicoOpcionalService } from 'src/app/shared/services/servico-opcional.service';

export class OpcionalResult{
  produto_revisao: number;
  sistematica: number; 
  tipo_revisao:number; 
  ciclo: number;
  seq:number;
  servicoOpcionalModel: ServicoOpcionalModel [] = []; 
}

@Component({
  selector: 'app-adicional-modal',
  templateUrl: './adicional-modal.component.html',
  styleUrls: ['./adicional-modal.component.scss']
})
export class AdicionalModalComponent implements OnInit {
  @Input() produto_revisao;
  @Input() sistematica; 
  @Input() tipo_revisao; 
  @Input() ciclo;
  @Input() seq;
  servicoOpcionalModel: ServicoOpcionalModel [] = []; 

  constructor( private bsModalRef: BsModalRef,
               protected servicoOpcionalService: ServicoOpcionalService) {
  }

  ngOnInit() {
    this.getServicoOpcional(this.produto_revisao, this.sistematica, this.tipo_revisao, this.ciclo, this.seq)
  }

  onClose(){
    this.bsModalRef.hide();
  }

  getServicoOpcional(produto_revisao: number, sistematica: number ,tipo_revisao:number ,ciclo: number,seq:number){
    this.servicoOpcionalService.getServicoOpcional(produto_revisao,sistematica,tipo_revisao,ciclo,seq).subscribe(
      result => {
        this.servicoOpcionalModel = result.content;
      }
    );
  }

}
