import { EventEmitter, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ResultApi } from "../interfaces/envelopes.interface";
import { AtendimentoModel } from "../models/atendimento.model";
import { DataService } from "./data.service";

@Injectable({
	providedIn: 'root',
})

export class DocumentsService {

    private url: string;

    EmmitNameChange = new EventEmitter<string>();
    EmmitUpload = new EventEmitter<{ nome: string }>();
    EmmitUploadAll = new EventEmitter<string>();
    EmmitDelete = new EventEmitter<string>();
    EmmitDownload = new EventEmitter<string>();
    EmmitVoltarDocs = new EventEmitter<string>();
    
	constructor(private dataService: DataService) { }

    putChange(name: string): void {
        this.EmmitNameChange.emit(name);
      }
    
    putUpload(nome): void {
      this.EmmitUpload.emit(nome)
    }
    
    putUploadAll(nome: string): void {
      this.EmmitUploadAll.emit(nome)
    }
  
    putDelete(name: string) : void {
      this.EmmitDelete.emit(name);
    }
  
    putDownload(name: string): void {
      this.EmmitDownload.emit(name)
    }

    getContentDocument(idDocumento: number) {
      this.url = `/Documentos/conteudo?idDocumento=${idDocumento}`;
      return this.dataService.get(this.url).pipe(catchError(e => throwError(this.erroHandle)));
    }

    getDocumentList(qtdeUltimosDias: number, somenteVigentes: boolean) {
      this.url = `/Documentos/list?qtdeUltimosDias=${qtdeUltimosDias}&somenteVigentes=${somenteVigentes}`;
      return this.dataService.get(this.url);
    }

    getDocumentGroup(idGrupo: string) {
      this.url = `/Documentos/GrupoDocumento?idGrupo=${idGrupo}`
      return this.dataService.get(this.url);
    }

    setDocuments(fileData: File, assunto: string, dtInicioVigencia: string, dtFimVigencia: string, tipoArquivo: string, numeroBoletim: string, substituicao: string) {

      const formData = new FormData()
      formData.append(fileData.name, fileData);
  
      if(substituicao == null) {
        this.url = `/Documentos?assunto=${assunto}&dtInicioVigencia=${dtInicioVigencia}&dtFimVigencia=${dtFimVigencia}&tipoArquivo=${tipoArquivo}&numeroBoletim=${numeroBoletim}`;
        return this.dataService.post(this.url, formData);
      }
  
      else {
        this.url = `/Documentos?assunto=${assunto}&dtInicioVigencia=${dtInicioVigencia}&dtFimVigencia=${dtFimVigencia}&tipoArquivo=${tipoArquivo}&numeroBoletim=${numeroBoletim}&substituicao=${substituicao}`;
        return this.dataService.post(this.url, formData);
      }
    }

    setDocumentsDateEnd(fileData: File, assunto: string, dtInicioVigencia: string, tipoArquivo: string, numeroBoletim: string, substituicao: string) {

      const formData = new FormData()
      formData.append(fileData.name, fileData);
  
      if (substituicao == null) {
        this.url = `/Documentos?assunto=${assunto}&dtInicioVigencia=${dtInicioVigencia}&numeroBoletim=${numeroBoletim}&tipoArquivo=${tipoArquivo}`;
        return this.dataService.post(this.url, formData);
      }
  
      else {
        this.url = `/Documentos?assunto=${assunto}&dtInicioVigencia=${dtInicioVigencia}&tipoArquivo=${tipoArquivo}&numeroBoletim=${numeroBoletim}&substituicao=${substituicao}`;
        return this.dataService.post(this.url, formData);
      }
    }

    updInfoDocument(idArquivo: number, assunto: string, dtInicioVigencia: string, dtFimVigencia: string, tipoArquivo: string, numeroBoletim: string, substituicao: string){

      if(substituicao == null) {
        this.url = `/Documentos?assunto=${assunto}&dtInicioVigencia=${dtInicioVigencia}&dtFimVigencia=${dtFimVigencia}&tipoArquivo=${tipoArquivo}&numeroBoletim=${numeroBoletim}&idArquivo=${idArquivo}`;
        return this.dataService.patch(this.url, '');
      }
  
      else {
        this.url = `/Documentos?assunto=${assunto}&dtInicioVigencia=${dtInicioVigencia}&dtFimVigencia=${dtFimVigencia}&tipoArquivo=${tipoArquivo}&numeroBoletim=${numeroBoletim}&substituicao=${substituicao}&idArquivo=${idArquivo}`;
        return this.dataService.patch(this.url, '');
      }
    }
  
    updInfoDocumentDateEnd(idArquivo: number, assunto: string, dtInicioVigencia: string, tipoArquivo: string, numeroBoletim: string, substituicao: string){
  
      if (substituicao == null) {
        this.url = `/Documentos?assunto=${assunto}&dtInicioVigencia=${dtInicioVigencia}&numeroBoletim=${numeroBoletim}&tipoArquivo=${tipoArquivo}&idArquivo=${idArquivo}`;
        return this.dataService.patch(this.url, '');
      }
  
      else {
        this.url = `/Documentos?assunto=${assunto}&dtInicioVigencia=${dtInicioVigencia}&tipoArquivo=${tipoArquivo}&numeroBoletim=${numeroBoletim}&substituicao=${substituicao}&idArquivo=${idArquivo}`;
        return this.dataService.patch(this.url, '');
      }
    }
  
    updDocument(fileData: File, idArquivo: number) {
      const formData = new FormData()
      formData.append(fileData.name, fileData);
  
      this.url = `/Documentos/Conteudo?idArquivo=${idArquivo}`;
      return this.dataService.patch(this.url, formData);
    }

    deleteDocument(idDocumento: number) {
      this.url = `/Documentos?idDocumento=${idDocumento}`;
      return this.dataService.delete(this.url);
    }

    getNotificationAmount(){
      this.url = `/Documentos/Notificacao`;
      return this.dataService.get(this.url);
    }

    getValidaPermissao(url){
      this.url = `/Menu/ValidaPermissaoSemRota?RotaCompleta=${url}`;
      return this.dataService.get(this.url);
    } 

    erroHandle(error) {

      let errorMessage = '';
  
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => {
        return errorMessage;
      });
    }
  
    putVoltar(nome): void {
      this.EmmitVoltarDocs.emit(nome)
    } 
}