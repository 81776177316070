<!-- <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-grid-pulse"
    [fullScreen]="true"
>
    <p style="color: white">Aguarde...</p>
</ngx-spinner> -->

<app-header></app-header>
<app-body></app-body>
<app-footer></app-footer>
