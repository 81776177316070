import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PermissionsFilialModel } from 'src/app/shared/models/PermissionsFilialModel.model';
import { UserConcessionarias } from 'src/app/shared/models/user-concessionarias.model';
import { UserModel } from 'src/app/shared/models/user-list.model';
import { postUserDealer } from 'src/app/shared/models/user-post-dealer.model';
import { UserFGModel } from 'src/app/shared/models/userfg.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DealerService } from 'src/app/shared/services/dealer.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { UserAccessService } from 'src/app/shared/services/user-manager.service';
import { UserService } from 'src/app/shared/services/user.service';
import * as _ from 'underscore';
import { Tipo } from './tipo';
import { Mercado } from './mercado';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClienteEspecialService } from 'src/app/shared/services/clienteespecial.service';


export class RegistrationNewUser {
  UserLogged: string;
  UserXds: string;
  EMail: string
  nomeCompleto: string;
  tipo: string;
  mu: string
}

@Component({
	selector: 'app-permissons-filial',
	templateUrl: './permissons-filial.component.html',
	styleUrls: ['./permissons-filial.component.scss'],
})
export class PermissonsFilialComponent implements OnInit {
	@Input() usuario: string;
	@Input() user_login: string;
	@Input() email: string;
	@Input() nome_completo: string;
	@Input() tipo: string;
	@Input() mu: string;

	form: FormGroup;
	orderBy = false;
	pageProducts: number;
	searchUser: string = '';
	userLogin: string;
	action: string;
	tipouser: string;

	p;
	concessionarias: any;
	concessionarias2: UserConcessionarias[];
	listaConcessionariasUser;
	listaConcessionariasUserCheck: postUserDealer;
	parentSelecttor: boolean = false;
	public userXds: string | null = null;
	userFG: UserFGModel; //Federation Gateway
	listOfUsers: UserModel[] = [];
	selected = [];
	userCon = [];
	messages = [];
	tipoSelecionado: Tipo = new Tipo(0, '--Selecione--');
	tipos: Tipo[];
	mercados: Mercado[];
	tipoMercado: boolean = false;
	sendAlertDay: number[] = [0];
	butDisabled: boolean = true;
	selectedValue: number;
	userScania: boolean = false;
	editScania: boolean = false;

	concessionariasSelect: postUserDealer;
	public saveUsername;

	constructor(
		private dealerService: DealerService,
		private userService: UserService,
		private globalService: GlobalService,
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private userAccessService: UserAccessService,
		private clienteEspecial: ClienteEspecialService,
		private bsModalRef: BsModalRef,
	) {

		this.form = this.formBuilder.group({
			nome: [null, Validators.required],
			xds: [null, [Validators.required, Validators.minLength(6)]],
			email: [
				null,
				[
					Validators.required,
					Validators.email,
					Validators.minLength(6),
				],
			],
			tipo: [null, Validators.required],
			mercado: [null, Validators.required],
		});
	}
	manager(codconcessionario: number, status: boolean) {
		let index = this.concessionarias.findIndex(
			(p) => p.codigo == codconcessionario
		);
		this.concessionarias[index].checado = status;
	}
	ngOnInit() {
		let user = this.userService.getUserFG().subscribe((dealer) => {
			this.userFG = dealer;
			if (this.action != 'U') {
				// if (this.userFG.tipo == 1 || this.userFG.tipo == 4) {
				//   this.editScania = false;
				// } else {
				//   this.editScania = true;
				// }
				this.mu = 'LB';
				if (this.userFG.tipo == 1) {
					this.tipos = this.globalService.getTipos();
					this.tipoMercado = false;
					this.tipoSelecionado.id = this.tipos[0].id;
					let teste = this.globalService.getMercado();
					this.selectedValue = teste[0].id;
				}

				if (this.userFG.tipo == 2) {
					this.tipos = this.globalService.getTipos2();
					this.tipoMercado = true;
					this.tipoSelecionado.id = this.tipos[0].id;
					let teste = this.globalService.getMercado();
					this.selectedValue = teste[0].id;
				}
				this.editScania = true;
				setTimeout(() => {
					this.loadDealer();
				}, 300);
			} else {
				if (this.tipouser == '1' || this.tipouser == '4')
					this.editScania = false;
				else this.editScania = true;
			}
			if (this.mu == 'CE') {
				this.loadClienteEspecial('');
				this.selectedValue = 2;
			} else {
				this.loadDealer();
				this.selectedValue = 1;
			}
			this.userAccessService
				.getUserAccessListUser(this.userFG.xds)
				.subscribe((listuser) => {
					this.listOfUsers = listuser;
				});
		});

		this.form.controls['mercado'].disable();
		this.tipos = this.globalService.getTipos();
	}

	onClose(): void {
		this.bsModalRef.hide();
	}

	loadDealer() {
		this.userXds = this.authService.getXdsUser().toUpperCase();
		console.log('Usuariologado', this.usuario);
		console.log('usuarioxds', this.userLogin);
		console.log('Tipousuario', this.tipouser);
		this.userXds = this.authService.getXdsUser().toUpperCase();
		//usuario logado Fdo4dh usuario registro que vai ser alterado ESCAFD
		this.dealerService
			.getDealerAdmin(this.userXds, this.userLogin)
			.subscribe((response) => {
				this.concessionarias = response.content;

				// this.tipo = this.concessionarias[0].tipo;

				this.dealerService
					.getUserDealer(this.userLogin)
					.subscribe((response) => {
						let zeroesq: string;
						this.listaConcessionariasUser = response;

						if (this.action != 'I') {
							if (
								this.listaConcessionariasUser.content.length >=
								1
							) {
								for (
									var i = 0;
									i <
									this.listaConcessionariasUser.content
										.length;
									i++
								) {
									zeroesq = this.globalService.addZeroes(
										this.listaConcessionariasUser.content[i]
											.codconc,
										5
									);

									const index =
										this.concessionarias.findIndex(
											(m) => m.codigo == zeroesq
										);

									this.concessionarias[index].checado = true;
								}
							}
						}
					});
			});
	}

	loadClienteEspecial(usuario: string) {
		let xdslogged = this.authService.getXdsUser().toUpperCase();
		this.clienteEspecial.getClienteEspecial(xdslogged).subscribe((ret) => {
			console.log(ret);
			this.concessionarias = ret.content;

			this.dealerService
				.getUserDealer(this.userLogin)
				.subscribe((response) => {
					let zeroesq: string;
					this.listaConcessionariasUser = response;

					if (this.action != 'I') {
						if (this.listaConcessionariasUser.content.length >= 1) {
							for (
								var i = 0;
								i <
								this.listaConcessionariasUser.content.length;
								i++
							) {
								//  zeroesq = this.globalService.addZeroes(this.listaConcessionariasUser.content[i].codconc, 5);

								const index = this.concessionarias.findIndex(
									(m) =>
										m.codigo ==
										this.listaConcessionariasUser.content[i]
											.codconc
								);

								this.concessionarias[index].checado = true;
							}
						}
					}
				});
		});
	}



	onSearch(search: string): void {
		this.pageProducts = 1;
		this.searchUser = search;
	}

	onChange2($event, codconcessionario) {
		let zeroesq = this.globalService.addZeroes(codconcessionario, 5);
		let achei = this.concessionarias2.findIndex(
			(m) => m.codconcessionario == zeroesq
		);
		if (this.concessionarias2[achei].checado) {
			this.concessionarias2[achei].checado = false;
		} else {
			this.concessionarias2[achei].checado = true;
		}
	}

	save2() {
		let selec = this.concessionarias.filter((m) => m.checado == true);

		let Concessionaires = [];

		selec.forEach((element) => {
			let cod = parseInt(element.codigo.toString());
			if (this.action === 'U')
				Concessionaires.push({
					codconc: cod,
					usuarioXds: this.userLogin,
				});
			else
				Concessionaires.push({
					codconc: cod,
					usuarioXds: this.form.get('xds').value.toUpperCase(),
				});
		});

		if (
			Concessionaires.length === 0 &&
			this.tipoSelecionado.id.toString() != '1' &&
			this.tipoSelecionado.id.toString() != '4'
		) {
			// Concessionaires.push({'codconc': 0, 'usuarioXds':this.userLogin});
			this.globalService.alert(
				'Filiais',
				'Para gravar é preciso pelo menos possuir uma concessionária vinculada',
				'error'
			);
			return;
		}

		if (this.action === 'U') {
			this.dealerService
				.postUserDealer(Concessionaires)
				.subscribe((response) => {
					// if (response.tipomessage === "success")
					//   this.globalService.alert(response.title, response.title, 'success');
					// else
					//   this.globalService.alert(response.title, response.title, 'error');
				});
			this.onClose();
		} else {
			this.registrationUser(Concessionaires);
		}
	}

	registrationUser(Concessionaires: UserConcessionarias[]) {
		let cadUserXds = new RegistrationNewUser();
		let user = this.authService.getUserData();

		cadUserXds.nomeCompleto = this.form.get('nome').value;
		cadUserXds.EMail = this.form.get('email').value.toUpperCase();
		cadUserXds.UserXds = this.form.get('xds').value.toUpperCase();
		cadUserXds.UserLogged = user.wam_user.xds.toUpperCase();
		cadUserXds.tipo = this.form.get('tipo').value;
		cadUserXds.mu = this.form.get('mercado').value;

		if (cadUserXds.tipo == '0') {
			this.globalService.alert(
				'Acesso',
				'Favor Informar o Tipo',
				'warning'
			);
			return;
		}

		if (cadUserXds.mu == '0') {
			this.globalService.alert(
				'Acesso',
				'Favor Informar o Mercado',
				'warning'
			);
			return;
		}

		if (cadUserXds.mu == '1') {
			cadUserXds.mu = 'LB';
		}

		if (cadUserXds.mu == '2') {
			cadUserXds.mu = 'CE';
		}

		if (this.tipoSelecionado.id == 1 || this.tipoSelecionado.id == 4) {
			this.userAccessService
				.postNewUser(cadUserXds)
				.subscribe((result) => {
					if (result.tipomessage === 'success') {
						this.bsModalRef.hide();
					}
				});
		} else {
			this.userAccessService
				.postNewUser(cadUserXds)
				.subscribe((result) => {
					if (result.msg === 'OK') {
						this.dealerService
							.postUserDealer(Concessionaires)
							.subscribe((response) => {});
						// this.result = true;
						this.bsModalRef.hide();
					}
				});
		}
	}

	onChangeCheck(event) {
		const id = event.target.value;
		const isChecked = event.target.checked;
		let obj = this.concessionarias;
		this.concessionarias = this.concessionarias.map((checkbox) => {
			if (checkbox.codconcessionario == id) {
				checkbox.select = isChecked;
				this.parentSelecttor = false;
				return checkbox;
			}
			if (id == -1) {
				checkbox.select = this.parentSelecttor;
				return checkbox;
			}
			return checkbox;
		});
	}

	sortTable(columnName: string): void {
		this.orderBy = !this.orderBy;
		if (this.orderBy) {
			this.concessionarias = _.sortBy(this.concessionarias, columnName);
		} else {
			this.concessionarias = _.sortBy(
				this.concessionarias,
				columnName
			).reverse();
		}
	}

	onSelect(id) {
		this.mercados = null;
		this.form.controls['mercado'].enable();
		this.loadDealer();

		if (this.tipoMercado == false) {
			this.mercados = null;
			this.mercados = this.globalService
				.getMercado()
				.filter((item) => item.tipoid == id);
			this.selectedValue = this.mercados[0].id;
		}

		if (this.tipoMercado == true) {
			this.mercados = null;
			this.mercados = this.globalService
				.getMercado2()
				.filter((item) => item.tipoid == id);
			this.selectedValue = this.mercados[0].id;
		}

		if (this.tipoSelecionado.id == 1 || this.tipoSelecionado.id == 4) {
			this.editScania = false;
		} else {
			this.editScania = true;
		}
	}

	ListaCeLB(idlbce) {
		if (idlbce == 1 || idlbce == null || idlbce == undefined) {
			this.loadDealer();
		} else {
			this.loadClienteEspecial(this.userLogin);
		}
	}
}
