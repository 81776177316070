<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-grid-pulse"
    [fullScreen]="true"
    name="callback"
>
    <p style="color: white">Aguarde...</p>
</ngx-spinner>
