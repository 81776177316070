import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserAccessDelModel } from 'src/app/shared/models/user-access-del.model';
import { UserAccessControlModel } from 'src/app/shared/models/user-access.model';
import { GlobalService } from 'src/app/shared/services/global.service';
import { UserAccessService } from 'src/app/shared/services/user-manager.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-edit-permissions-user-modal',
	templateUrl: './edit-permissions-user-modal.component.html',
	styleUrls: ['./edit-permissions-user-modal.component.scss'],
})
export class EditPermissionsUserModalComponent implements OnInit {
	@Input() usuario: string;
	@Input() e_mail: string;
	@Input() nome_completo: string;
	@Input() cod_conc: number;
	@Input() user_login: string;
	@Input() tipo: number;

	form: FormGroup;
	user_codconc: number;
	private listUserAccess: UserAccessControlModel[] = [];

	// private listUserAccess: UserAccessEditModel[] = [];

	menus = [
		{
			name: 'Home',
			descricao: 'Home',
			routerLink: '/Home',
			menuId: 1,
			subMenuId: 1,
			accessId: 0,
			checado: false,
			xdsUser: '',
			visible: 'S',
		},
		{
			name: 'Usuario',
			descricao: 'Usuario',
			routerLink: '/users',
			menuId: 3,
			subMenuId: 6,
			accessId: 0,
			checado: false,
			xdsUser: '',
			visible: 'S',
		},
		{
			name: 'Revisões',
			descricao: 'atendimento',
			routerLink: '/revisoes/Atendimento',
			menuId: 2,
			subMenuId: 2,
			accessId: 0,
			checado: false,
			visible: 'S',
		},
		{
			name: 'Revisões',
			descricao: 'cadastro',
			routerLink: '/revisoes/Cadastro',
			menuId: 2,
			subMenuId: 3,
			accessId: 0,
			checado: false,
			visible: 'S',
		},
		{
			name: 'revisoes',
			descricao: 'consulta',
			routerLink: '/revisoes/Consulta',
			menuId: 2,
			subMenuId: 4,
			accessId: 0,
			checado: false,
			visible: 'S',
		},
		{
			name: 'revisoes',
			descricao: 'proprietario',
			routerLink: '/revisoes/Proprietario',
			menuId: 2,
			subMenuId: 5,
			accessId: 0,
			checado: false,
			visible: 'S',
		},
		{
			name: 'documentos',
			descricao: 'documentos',
			routerLink: '/documentos/boletim-circular',
			menuId: 4,
			subMenuId: 7,
			accessId: 0,
			checado: false,
			visible: 'S',
		},
		{
			name: 'updoc',
			descricao: 'updoc',
			routerLink: '/documents/boletim-circular-upload',
			menuId: 4,
			subMenuId: 8,
			accessId: 0,
			checado: false,
			visible: 'S',
		},
	];

	constructor(
		private formBuilder: FormBuilder,
		private userAccessService: UserAccessService,
		private globalService: GlobalService,
		private modalService: BsModalService
	) {

		this.form = this.formBuilder.group({
			home: [true],
			atendimento: [false],
			cadastro: [false],
			consulta: [false],
			proprietario: [false],
			usuarios: [false],
			documentos: [false],
			updoc: [false],
		});
	}

	ngOnInit() {
		setTimeout(() => {
			this.getMenuUser();
		}, 200);
	}

	getMenuUser() {
		// let user = this.authService.getXdsUser();
		this.userAccessService
			.getUserAccessMenu(this.user_login)
			.subscribe((result) => {
				this.listUserAccess = result;
				this.listUserAccess.forEach((element) => {
					const index = this.menus.findIndex(
						(controller) =>
							controller.menuId === element.menuId &&
							controller.subMenuId === element.subMenuId &&
							element.userAccessId != 0
					);
					if (index >= 0) {
						this.menus[index].checado = true;

						if (
							this.menus[index].descricao.toLowerCase() === 'home'
						)
							this.form.patchValue({
								home: true,
							});

						if (
							this.menus[index].descricao.toLowerCase() ===
							'atendimento'
						)
							this.form.patchValue({
								atendimento: true,
							});

						if (
							this.menus[index].descricao.toLowerCase() ===
							'usuario'
						)
							this.form.patchValue({
								usuarios: true,
							});

						if (
							this.menus[index].descricao.toLowerCase() ===
							'cadastro'
						)
							this.form.patchValue({
								cadastro: true,
							});

						if (
							this.menus[index].descricao.toLowerCase() ===
							'consulta'
						)
							this.form.patchValue({
								consulta: true,
							});

						if (
							this.menus[index].descricao.toLowerCase() ===
							'proprietario'
						)
							this.form.patchValue({
								proprietario: true,
							});

						if (
							this.menus[index].descricao.toLowerCase() ===
							'documentos'
						)
							this.form.patchValue({
								documentos: true,
							});

						if (
							this.menus[index].descricao.toLowerCase() ===
							'updoc'
						)
							this.form.patchValue({
								updoc: true,
							});
					}
				});
				console.log('codconc', this.cod_conc);
				this.user_codconc = this.cod_conc;
			});
	}

	onClose(modalId?: number): void {
		this.modalService.hide(modalId);
	}

	onSaveChanges(): void {
		this.menus[0].checado = true;
		this.menus[0].xdsUser = this.user_login;
		this.menus[1].checado = this.form.get('usuarios').value;
		this.menus[1].xdsUser = this.user_login;
		this.menus[2].checado = this.form.get('atendimento').value;
		this.menus[2].xdsUser = this.user_login;
		this.menus[3].checado = this.form.get('cadastro').value;
		this.menus[3].xdsUser = this.user_login;
		this.menus[4].checado = this.form.get('consulta').value;
		this.menus[4].xdsUser = this.user_login;
		this.menus[5].checado = this.form.get('proprietario').value;
		this.menus[5].xdsUser = this.user_login;
		this.menus[6].checado = this.form.get('documentos').value;
		this.menus[6].xdsUser = this.user_login;
		this.menus[7].checado = this.form.get('updoc').value;
		this.menus[7].xdsUser = this.user_login;

		let menuaccess = this.menus.filter((acc) => acc.checado === true);
		let user = new UserAccessDelModel();
		user.xdsUser = this.user_login;

		this.userAccessService
			.deleteUserAccessBySystemId(user)
			.subscribe((ret) => {
				this.userAccessService
					.postUserAccess(menuaccess)
					.subscribe((result) => {
						if (result[0].s_msg === 'OK') {
							this.globalService.alert(
								'Acesso',
								'Acesso alterado com sucesseo',
								'success'
							);
							this.onClose();
						} else
							this.globalService.alert(
								'Acesso',
								'Contate po administrador de sistema',
								'warning'
							);
					});
			});
	}
}
