import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { Message } from '../interfaces/envelopes.interface';

export function handleApiError(
    errorResponse: HttpErrorResponse
): Observable<never> {
    let errorMessage: Message = {
        content: 'Erro desconhecido',
        messageType: 'Error',
    };

    switch (errorResponse.status.toString().substring(0, 1)) {
        case '4':
            errorMessage = errorBadRequest(errorResponse);
            break;
        case '5':
            errorMessage = errorInternalServerError(errorResponse);
            break;
        default:
            errorMessage = errorDefault();
            break;
    }

    return throwError(errorMessage);
}

function errorDefault(): Message {
    const errorMessage: Message = {
        content: 'Erro ao tentar estabelecer conexão com o servidor',
        messageType: 'Erro desconhecido',
    };

    // alert(errorMessage.content);

    return errorMessage;
}

function errorBadRequest(errorResponse: HttpErrorResponse): Message {
    const errorMessage: Message = {
        content: errorResponse.error.messages,
        messageType: 'Bad Request',
    };

    return errorMessage;
}

function errorInternalServerError(errorResponse: HttpErrorResponse): Message {
    const errorMessage: Message = {
        content: errorResponse.error.messages,
        messageType: 'Internal Server Error',
    };

    // alert(errorMessage.content);

    return errorMessage;
}
