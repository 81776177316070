import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { CadastroComponent } from 'src/app/sections/revisoes/cadastro/cadastro.component';
import { RevisaoIncModel } from 'src/app/shared/models/revisao-inc.model';
import { GlobalService } from 'src/app/shared/services/global.service';
import { RevisaoConsultaService } from 'src/app/shared/services/revisao-consulta.service';

@Component({
  selector: 'app-revisao-modal',
  standalone: false,
  templateUrl: './revisao-modal.component.html',
  styleUrl: './revisao-modal.component.scss'
})

export class RevisaoModalComponent implements OnInit {
  @Input() revisao;
  @Input() mks;
  @Input() pro_revisao;
  @Input() sistematica;
  @Input() serie_produto;
  @Input() pro_type;
  @Input() tipo_comercializacao;
  @Input() horas_motor;
  @Input() opr_type;

  public modalRef: BsModalRef;
  forminc: FormGroup;
  revisaoIncModel = new RevisaoIncModel();
  public onClose: Subject<Boolean>;

  constructor(private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private globalService: GlobalService,
    private revisaoConsultaService: RevisaoConsultaService,
  ) {

    this.forminc = this.formBuilder.group({
      chassi: [{ value: '', disabled: true }],
      motor: [{ value: '', disabled: true }],
      concessionaria: [{ value: '', disabled: true }],
      revrev: [{ value: '', disabled: true }],
      tpoperrev: [{ value: '', disabled: true }],
      km: [{ value: '', disabled: true }],
      hm: [{ value: '', disabled: true }],
      datarev: [{ value: '', disabled: true }],
      osrev: [{ value: '', disabled: true }]
    })
  }
  ngOnInit(): void { }
  showConfirmationModal(chassi: string, motor: string, concessionaria: string, revrev: string, tpoperrev: string, data: string, km: number, hm: number, osrev: number): void {
    this.forminc.patchValue({
      chassi: chassi,
      motor: motor,
      concessionaria: concessionaria,
      revrev: revrev,
      tpoperrev: tpoperrev,
      km: km,
      hm: hm,
      datarev: data,
      osrev: osrev,
    });
  }

  Close() {
    this.modalService.hide();
  }

  salvarMudanca() {
    debugger
    let codconc = this.forminc.get('concessionaria').value;
    let codconc2 = codconc.substring(0, 4);
    let km = this.forminc.get('km').value;
    let hm = this.forminc.get('hm').value;
    let tr = this.forminc.get('tpoperrev').value;
    let tr2 = tr.substring(1, 4);
    if (tr2 == ""! || tr2 == null)
      tr2 = this.opr_type;

    this.revisaoIncModel.codigo = null;
    this.revisaoIncModel.concessionaria = parseInt(codconc2);
    this.revisaoIncModel.kilometragem = km;
    this.revisaoIncModel.horas_motor = hm;
    this.revisaoIncModel.dt_revisao = this.forminc.get('datarev').value;
    this.revisaoIncModel.tipo_revisao = this.revisao;
    this.revisaoIncModel.id_ordem_servico = this.forminc.get('osrev').value;
    this.revisaoIncModel.opr_type = tr2;
    this.revisaoIncModel.mks_code = this.mks;
    this.revisaoIncModel.produto_revisao = this.pro_revisao;
    this.revisaoIncModel.sistematica = this.sistematica;
    this.revisaoIncModel.serie_produto = this.serie_produto;
    this.revisaoIncModel.pro_type = this.pro_type;
    this.revisaoIncModel.tipo_comercializacao = this.tipo_comercializacao;


    this.revisaoConsultaService.postRevisao(this.revisaoIncModel).subscribe(
      res => {
        const name = "revisao";
        this.revisaoConsultaService.uploadRevision(name)
        if (res.tipomessage === 'success') {
          let mensagem = '';
          // aqui vai verificar qual mensagem está presente
          if
            (res.content.mensagem1) { mensagem = res.content.mensagem1; }
          else
            if
              (res.content.mensagem2) { mensagem = res.content.mensagem2; }
            else
              if
                (res.content.mensagem3) { mensagem = res.content.mensagem3; }

          this.globalService.alert("Revisão", mensagem, res.tipomessage);
          this.Close();
        }
        else {
          this.globalService.alert("Revisão", res.msg, res.tipomessage);
          this.Close();
        }
      }
    );

  }


}