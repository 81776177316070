<c-header class="site-header" site-name="Revisões Scania">
    <div slot="items" *ngIf="userAuthentication">
        Olá, {{ user.name }} <b>({{ user.wam_user.xds }})</b>
    </div>
    <div slot="items" *ngIf="!userAuthentication">
        <button type="button" class="btn btn-primary" (click)="onClickLogin()">
            Login
        </button>
    </div>
</c-header>
