import { AuthService } from '../services/auth.service';
import { iif, Observable, of, throwError, timer } from 'rxjs';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	catchError,
	delayWhen,
	filter,
	finalize,
	map,
	retry,
	retryWhen,
	switchMap,
	take,
	tap,
} from 'rxjs/operators';
import { handleApiError } from '../functions/api-errors.function';
import { TokenResponseModel } from '../models/auth-token.model';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '../services/global.service';

@Injectable({
	providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService,
		private spinner: NgxSpinnerService,
		private globalService: GlobalService,
	) {}

	private Activerequest = 0;
	private refreshToken(): Observable<string> {
		return this.authService
			.getRefreshToken(this.authService.getResfreshTokenValue())
			.pipe(
				tap((result) => this.authService.setToken(result)),
				map((result) => result.access_token)
			);
	}

	private setAuthorizationHeader(
		request: HttpRequest<any>,
		token: string
	): HttpRequest<any> {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
				// 'content-type': 'application/json'
			},
		});
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (this.Activerequest === 0) {

			this.spinner.show();
		}
		this.Activerequest++;
		let urlCep = request.url.substring(0,21); 
		// let tamcep = 'https://viacep.com.br'
		if (
			request.url != environment.wso2.tokenUri &&
			this.authService.getTokenValue() &&
			urlCep != 'https://viacep.com.br'
		) {
			request = this.setAuthorizationHeader(
				request,
				this.authService.getTokenValue()
			);
		}

		return next.handle(request).pipe(
			// Não APAGAR. aLTERAÇÕES PARA O FUTURO
			tap(
				(event: any) => {
				  if (event.status === 200){
					
					if (event.body.sendmsg === true){	
					  this.globalService.alert(event.body.msg,event.body.title,event.body.tipomessage);
					}
				}	
				  
			}),
			catchError((responseError: HttpErrorResponse) => {
				if (
					responseError.status === 401 ||
					responseError.status === 403
				) {
					return this.refreshToken().pipe(
						switchMap((token) => {
							request = this.setAuthorizationHeader(
								request,
								token
							);
							return next.handle(request);
						}),
						catchError((err) => {
							Swal.fire({
								title: `Sessão expirada`,
								text: 'Faça o login novamente',
								icon: 'info',
								showConfirmButton: true,
								showCancelButton: false,
								confirmButtonText: 'Ok',
								cancelButtonText: 'Não',
								confirmButtonColor: '#033a6a',
								cancelButtonColor: '#CC1040',
								allowOutsideClick: false,
							});

							this.authService.userLogout();

							return throwError(err);
						})
					);
				}

				if (
					responseError.status === 400 || responseError.status === 500
				){
					Swal.fire({
						title: 'Error',
						text: 	responseError.error.title,
						icon: 'error',
						showConfirmButton: true,
						showCancelButton: false,
						confirmButtonText: 'Ok',
						cancelButtonText: 'Não',
						confirmButtonColor: '#033a6a',
						cancelButtonColor: '#CC1040',
						allowOutsideClick: false,
					});

				}

				if (
					responseError.status === 525
				){
					Swal.fire({
						title: "Atenção",
						text: 	responseError.error.title,
						icon: 'warning',
						showConfirmButton: true,
						showCancelButton: false,
						confirmButtonText: 'Ok',
						cancelButtonText: 'Não',
						confirmButtonColor: '#033a6a',
						cancelButtonColor: '#CC1040',
						allowOutsideClick: false,
					});

				}

				return handleApiError(responseError);
			}),
			finalize(() => {
				this.Activerequest--;
				if (this.Activerequest === 0) {

					this.spinner.hide();
				}
			})
		);
	}
}
